.singleProduct_section{
    padding: 50px 0px;
    .product_container{
        display: flex;
        align-items: center;
        height: 100%;
    }
    .product_image_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
@media screen and (max-width: 575px) {
    .singleProduct_section{
        padding: 20px 0px;
    }
}