$header_bottom_gradient: linear-gradient(54deg,
        #d9bdff 0%,
        #a0c9fb 25%,
        #79aade 43%,
        #ba93f0 61%,
        rgb(223 170 52 / 75%) 83%);

.site_main_head {
    position: relative;
    z-index: 5;

    .top_most_bar {
        background-color: #fafafb;
        z-index: 2;
    }

    .top_bar {
        display: flex;
        padding: 10px 20px;
        // border-bottom: 1px solid lightgrey;
        // box-shadow: inset 0px 0px 20px rgb(128 128 128 / 30%);
        justify-content: space-around;
        align-items: center;

        .mail_link {
            text-decoration: none;
            color: black;

            img {
                width: 34px;
                height: auto;
            }
        }

        .call_us_div {
            display: flex;
            height: 25px;
            overflow: hidden;

            .call_us_list {
                height: 100px;
                list-style-type: none;
                transform: translateY(0px);
                transition: all 0.5s;
                line-height: 2;
                animation-name: scroll;
                animation-duration: 7s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                // animation-timing-function: steps(1);

                &:hover {
                    animation-play-state: paused;
                }

                @keyframes scroll {
                    0% {
                        transform: translateY(20px);
                    }

                    // 25%{transform: translateY(-25px);}
                    // 50%{transform: translateY(-35px);}
                    // 75%{transform: translateY(-75px);}
                    100% {
                        transform: translateY(-160px);
                    }
                }

                li {
                    margin: 0px 10px;
                    display: flex;

                    .country_name {
                        min-width: 80px;
                        padding-right: 20px;
                    }
                }
            }
        }

        .social_link_div {
            display: flex;
            width: 200px;
            justify-content: space-between;
            align-items: center;
            font-size: 1.1em;

            a {
                img {
                    width: 20px;
                    height: auto;
                    margin: 0px 10px;
                    transition: all 0.5s;

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }

    .menu_bar_container {
        position: relative;

        &::before {}
    }

    .menu_bar_div {
        border-top: 1px solid lightgrey;
        // box-shadow: 0px 10px 40px #cbcbcb4a;
        // color: #fff;
        position: relative;
        width: 100%;
        background: #fff;

        .animated_bar {}

        &::before {
            content: "";
            position: absolute;
            top: calc(100px - 27px);
            width: 100%;
            height: 3px;
            // background:linear-gradient(90deg, rgba(255,166,0,1) 0%, rgba(9,121,92,1) 44%, rgba(0,212,255,1) 100%);
            // background: linear-gradient(54deg, rgba(0,255,188,1) 0%, rgba(33,203,152,1) 45%, rgba(0,212,255,1) 100%);
            // background: linear-gradient(54deg,
            //         rgba(0, 255, 188, 1) 0%,
            //         rgba(33, 203, 152, 1) 25%,
            //         rgba(0, 212, 255, 1) 43%,
            //         rgba(0, 212, 255, 1) 61%,
            //         rgba(255, 158, 0, 1) 83%);
            background: $header_bottom_gradient;
            background-size: 400% 400%;
            transform: translateY(3px);
            animation: moving 5s linear infinite;
            z-index: 2;
        }

        @keyframes moving {
            0% {
                background-position: 0% 50%;
            }

            50% {
                background-position: 100% 50%;
            }

            100% {
                background-position: 0% 50%;
            }
        }

        .nav_bar {
            .main_nav {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .site_logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1em 0em;

                    .site_logo_img {
                        img {
                            width: 200px;
                            // width: 150px;
                            margin-right: 10px;
                        }
                    }

                    .site_logo_name {
                        font-weight: 500;
                        font-size: 1.8em;
                    }
                }

                .main_nav_list {
                    display: flex;
                    align-items: center;

                    .main_nav_ul {
                        list-style-type: none;
                        display: flex;
                        margin: 0%;

                        .main_nav_link.main_nav_Drop_link {
                            padding: 20px;
                            cursor: pointer;
                            font-weight: 600;
                            color: #000545;
                            position: relative;
                            z-index: 0;
                            overflow: hidden;

                            &::after {
                                content: "";
                                position: absolute;
                                top: 0%;
                                left: 0%;
                                width: 100%;
                                height: 100%;
                                background-color: transparent;
                            }
                        }

                        .main_nav_link {
                            padding: 20px;
                            cursor: pointer;
                            font-weight: 600;
                            color: #252121;
                            position: relative;
                            z-index: 0;
                            overflow: hidden;

                            &::before {
                                content: "";
                                position: absolute;
                                bottom: calc(100% - 80%);
                                left: -100%;
                                // transform: translate(-50%, -50%);
                                width: 5px;
                                height: 5px;
                                border-radius: 50%;
                                background-color: orangered;
                                z-index: -1;
                                transition: all 0.3s;
                                // transform: scale(0);
                            }

                            &:hover::before {
                                // transform: scale(40);
                                left: calc(100% - 50%);
                            }

                            &:hover {
                                color: orangered;
                            }

                            &:hover>a {
                                color: orangered;
                            }

                            a {
                                &:hover {
                                    color: orangered;
                                }

                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 0%;
                                    left: 0%;
                                    width: 100%;
                                    height: 100%;
                                    background-color: transparent;
                                }
                            }
                        }
                    }

                    .menu_toggle_btn {
                        display: none;
                        width: 34px;
                        height: auto;
                        border: none;
                        background: none;
                        overflow: hidden;
                        position: relative;
                        margin: 25px;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0%;
                            left: 0%;
                            width: 100%;
                            height: 100%;
                            background-color: transparent;
                            z-index: 10;
                        }

                        .menu_bar1 {
                            width: 100%;
                            height: 2px;
                            background-color: #000;
                            border-radius: 10px;
                            margin: 8px 0px;
                            transform-origin: 0px;
                            transform: rotate(0deg);
                            transition: all 0.5s;
                        }

                        .menu_bar2 {
                            width: 100%;
                            height: 2px;
                            background-color: #000;
                            border-radius: 10px;
                            margin: 8px 0px;
                            transform: translateX(10px);
                            transition: all 0.5s;
                        }

                        .menu_bar3 {
                            width: 100%;
                            height: 2px;
                            background-color: #000;
                            border-radius: 10px;
                            margin: 8px 0px;
                            transform-origin: 0px;
                            transform: rotate(0deg);
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }

        .desk_dropdown {
            position: absolute;
            top: 70px;
            // left: -400%;
            // width: max-content;
            left: 0%;
            width: 100%;
            // background-color: #f7f7f7;
            // background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.92), rgba(241, 241, 241, 0.92)),
            //     url("../../media/BLOCKCHAIN\ IMG02.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            // border-top: 2px solid grey;
            // box-shadow: 0px 12px 20px #80808026;
            // transition: all 0.5s;
            backdrop-filter: blur(10px);
            perspective: 1000px;
            transform: scaleY(0);
            transition: all 0.2s;
            transform-origin: center top;
            // opacity: 0;
            // transform: translateY(-500px);
            // animation: fade 0.3s linear 1 forwards;
            // opacity: 0;
            // overflow-y: scroll;
            // height: 250px;
            // display: none;
            align-items: center;
            z-index: -1;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                width: 5px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                width: 5px;
                background-color: orangered;
                border-radius: 25px;
            }

            .container {
                // opacity: 0;
                // min-height: 0px;
                // height: 0px;
                // overflow-y: scroll;
                // overflow-x: hidden;
                width: 130%;
                // transform: rotateX(330deg);
                background: #fff;
                padding: 2em;
                border-bottom: 4px solid #ffb995;
                border-radius: 5px;
                margin-top: -0.49em;
                box-shadow: rgb(17 17 26 / 10%) 0px 5px 16px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-track {
                    width: 4px;
                    background-color: #fafafb;
                }

                &::-webkit-scrollbar-thumb {
                    width: 4px;
                    background-color: #ba93f0;
                }

                .row {
                    .service_sideNav {
                        background-color: rgba(71, 87, 105, 0.0784313725);
                        padding: 2em 1em;
                        border-radius: 5px;
                    }

                    .nav.nav-pills {
                        position: relative;

                        .nav-item {
                            position: relative;
                        }

                        .hover_nav_link {
                            transition: all .2s;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #000000;
                            border-radius: 5px;
                            mix-blend-mode: soft-light;
                            pointer-events: none;
                        }
                    }

                    .nav-pills .nav-link {
                        color: #000;
                        font-weight: 700;
                        cursor: pointer;
                        letter-spacing: 1px;

                        @media screen and (min-width:1025px) and (max-width:1260px) {
                            font-size: 12px;
                            color: #000;
                            font-weight: 500;
                            cursor: pointer;
                            letter-spacing: .5px;
                        }

                        @media screen and (min-width:1260px) and (max-width:1400px) {
                            font-size: 14px;
                            color: #000;
                            font-weight: 500;
                            cursor: pointer;
                            letter-spacing: .5px;
                        }
                    }

                    // .nav-pills .nav-link {
                    //     // background-color: #47576a9f;
                    //     // color: #ffffff;
                    //     // pointer-events: none;
                    // }

                    .nav-pills .nav-link.active,
                    .nav-pills .show>.nav-link {
                        background-color: #47576a;
                        color: #ffffff;
                    }

                    .dropdown_bg_image {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        flex-direction: column-reverse;
                        justify-content: center;

                        h5 {
                            width: 100%;
                            text-align: center;
                            background: #c82292;
                            padding: 0.2em;
                            color: #fff;
                            margin-top: 0.51em;
                            border-radius: 4px;
                            font-weight: 600;
                            letter-spacing: 1px;
                            box-shadow: 0 0 0 #c82292;
                            animation: pulse 1.5s infinite;

                            @keyframes pulse {
                                0% {
                                    -moz-box-shadow: 0 0 0 0 #c82292;
                                    box-shadow: 0 0 0 0 #c82292;
                                }

                                70% {
                                    -moz-box-shadow: 0 0 0 10px #c82292;
                                    box-shadow: 0 0 0 10px rgba(200, 34, 145, 0);
                                }

                                100% {
                                    -moz-box-shadow: 0 0 0 0 #c82292;
                                    box-shadow: 0 0 0 0 rgba(200, 34, 145, 0);
                                }
                            }
                        }
                    }

                    .tab-content {
                        ul {
                            overflow: hidden;
                            // line-height: 2;
                            padding: 1em 0em;
                            flex-basis: 25%;
                            position: relative;

                            li {
                                position: relative;
                                padding-bottom: 10px;
                            }
                        }

                        .desk_dropdown_link {
                            display: flex;
                            align-items: center;
                            // transform: translateX(-15px);
                            width: fit-content;
                            transition: all 0.3s;
                            color: #47576a;

                            &:hover {
                                // font-weight: 600;
                                // transform: translateX(0px);
                                color: #475769;
                            }

                            svg {
                                margin-right: 2px;
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                top: 0%;
                                left: 0%;
                                width: 100%;
                                height: 100%;
                                background-color: transparent;
                            }
                        }
                    }

                    .desk_dropdown_link {
                        display: flex;
                        align-items: center;
                        transform: translateX(0px);
                        width: fit-content;
                        transition: all 0.3s;
                        color: #47576a;

                        &:hover {
                            // font-weight: 600;
                            transform: translateX(0px);
                            color: #475769;

                            //priyanka
                            svg {
                                opacity: 1;
                                transform: scale(1.5);
                            }

                            //priyanka
                        }

                        svg {
                            transition: all .2s;
                            margin-right: 25px;
                            //priyanka
                            opacity: 0;
                            //priyanka
                            animation: growingDot .5s ease-in-out infinite;

                            @keyframes growingDot {
                                0% {
                                    transform: scale(1.5);
                                }

                                50% {
                                    transform: scale(2.5);
                                }
                                100% {
                                    transform: scale(1.5);
                                }
                            }
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0%;
                            left: 0%;
                            width: 100%;
                            height: 100%;
                            background-color: transparent;
                        }
                    }
                }
            }

            // @keyframes fade {
            //     0% {
            //         opacity: 1;
            //         height: 0px;
            //     }

            //     100% {
            //         opacity: 1;
            //         height: 300px;
            //     }
            // }

            .warning {
                display: flex;
                justify-content: center;

                b {
                    display: flex;
                    align-items: center;

                    svg {
                        color: #ffb600;
                        font-size: 2em;
                        margin-right: 0.5em;
                    }
                }
            }

            .technology {
                background-image: linear-gradient(45deg, rgb(150, 0, 158), rgb(75, 0, 48));
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                outline: 2px solid #96009e;
                outline-offset: 2px;

                h2 {
                    color: #fff;
                }
            }

            .desk_dropdown_col {
                display: flex;
                height: 100%;
                align-items: center;

                .insight_deskDrop img {
                    width: 250px;
                    height: 150px;
                    object-fit: cover;
                    border-radius: 5px;
                    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                }

                .desk_dropdown_subhead {
                    font-size: 1.2em;
                    font-weight: 600;
                    color: #252121;
                    margin-top: 1em;
                }

                ul {
                    list-style-type: none;
                    padding: 0%;
                    overflow: hidden;
                    line-height: 2;

                    li {
                        .desk_dropdown_link {
                            display: flex;
                            align-items: center;
                            transform: translateX(-15px);
                            width: max-content;
                            transition: all 0.5s;
                            color: #47576a;

                            &:hover {
                                transform: translateX(0px);
                                color: #9c27b0;
                            }

                            svg {
                                margin-right: 25px;
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                top: 0%;
                                left: 0%;
                                width: 100%;
                                height: 100%;
                                background-color: transparent;
                            }
                        }

                    }
                }
            }

            .our_company_img {
                height: 200px;
                width: 100%;
                object-fit: fill;
            }
        }

        .desk_dropdown_link.all_products {
            background-color: #9c27b0;
            width: 100%;
            color: #fff;
            border-radius: 5px;
            padding: 0px 15px;
            font-weight: 600;
        }

        .mobile_menu_section {
            display: none;
            background-color: #000c;
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 90px;
            left: 0%;
            transition: all 0.5s;
            z-index: -1;

            .mobile_menu_div {
                background-color: #fafafb;
                transition: all 0.5s ease 0.5s;
                width: 0%;
                height: 100vh;
                overflow-y: scroll;
                // transform: translateX(-100%);
                animation: slide 0.3s linear 0.3s 1 forwards;
                opacity: 0;

                @keyframes slide {
                    0% {
                        width: 0%;
                        opacity: 0;
                    }

                    100% {
                        width: 100%;
                        opacity: 1;
                    }
                }

                ul {
                    list-style-type: none;
                    padding: 0%;
                    line-height: 2;
                    padding: 0px 30px;

                    .mobile_drop_link.with_dropdown {

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0%;
                            left: 0%;
                            width: 100%;
                            height: 100%;
                            background: transparent;
                            z-index: 1;
                        }
                    }

                    li {
                        padding: 5px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;


                        .downArrow {
                            transition: all 0.5s;
                        }

                        // &::before {
                        //     content: "";
                        //     position: absolute;
                        //     top: 0%;
                        //     left: 0%;
                        //     width: 100%;
                        //     height: 100%;
                        //     z-index: 2;
                        // }
                    }

                    .mobile_drop_menu_list {
                        transition: all 0.2s;
                        overflow-y: scroll;
                        height: 0px !important;
                        padding: 0% !important;
                        display: list-item;
                        height: 210px;
                        padding: 10px;
                        box-shadow: rgb(241 241 241) 0px 0px 15px inset;
                        background-color: #fafafb;

                        &::-webkit-scrollbar {
                            width: 2px;
                        }

                        &::-webkit-scrollbar-track {
                            width: 2px;
                            background-color: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            width: 2px;
                            background-color: lightgrey;
                            border-radius: 25px;
                        }

                        .mobile_drop_menu {
                            .subheading_text {
                                font-size: 15px;
                                font-weight: 600;
                                color: #000;

                                ul {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .sticky {
        position: fixed;
        top: -1%;
        width: 100%;
    }

    .head {
        position: absolute;
        top: 0%;
        background-color: #fff;
        // box-shadow: 0px 10px 20px #00000026;
    }
}

@media screen and (min-width: 1025px) {
    .site_main_head .menu_bar_div .mobile_menu_section {
        display: none !important;
    }
}

@media screen and (max-width: 1025px) {
    .menu_bar_div {
        &::before {
            top: 83px !important;
        }
    }

    .mobile_menu_section {
        display: block;
    }

    .site_main_head {
        .top_bar {
            display: none;
        }
    }

    .main_nav_list {
        ul {
            display: none !important;
        }
    }

    .menu_toggle_btn {
        display: block !important;
    }
}

// ===================== Quote area ======================
.quote_wrap {
    position: relative;

    button {
        background-color: #00d26d;
        display: inline-block;
        position: fixed;
        right: 10px;
        transform: rotate(0deg);
        /* top: 50%; */
        z-index: 10;
        border-radius: 5px;
        bottom: 10px;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 0%;
            left: -100%;
            width: 200%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            transform: rotate(45deg);
            transition: all 0.5s;
            animation: swipe 1s linear infinite;
        }

        @keyframes swipe {
            0% {
                left: -100%;
            }

            100% {
                left: 100%;
            }
        }
    }

    p {
        color: #fff;
        font-size: 18px;

        // span {
        //     font-weight: 900;
        // }
    }

    button {
        // background-color: transparent;
        border: none;
        transition: all 0.5s ease;

        &:focus {
            background-color: #00d26d;
            border: none;
        }

        &:active {
            background-color: #00d26d;
            border: none;
        }

        &:hover {
            opacity: 0.8;
            background-color: #00d26d;
            border: none;
        }
    }

    .popup-icn {
        display: none;
    }
}

.congrats_popup.get_popup {
    height: 100% !important;
}

.modal-content {
    border: 1px solid #fdad4c;
    box-shadow: 0px 0px 10px #ffb44e !important;
}

.modal-title {
    color: #000;
    text-align: center;
    width: 100%;
    font-weight: 600;
}

// .btn-close {
//     position: relative;
//     top: -21px;
//     left: 12px;
//     box-shadow: 0px 0px 20px 0px #ccc;
//     border-radius: 50%;

//     &:focus {
//         box-shadow: none;
//     }
// }

// .btn-close::after {
//     position: absolute;
//     left: 0px;
//     bottom: 0px;
//     content: "X";
//     height: 30px;
//     width: 30px;
//     background: #ffffff;
//     line-height: 30px;
//     color: #000000;
//     border-radius: 50%;
//     font-size: 16px;
//     border: 1px solid #000;
// }

// }
// .login-box {
// position: absolute;
// top: 50%;
// left: 50%;
// width: 400px;
// padding: 40px;
// transform: translate(-50%, -50%);
// background: rgba(0, 0, 0, 0.5);
// box-sizing: border-box;
// box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
// border-radius: 10px;
// }

.quote-popup-wrap {
    display: flex;
}

.quote-popup-img {
    width: 50%;

    img {
        object-fit: cover;
        height: 100%;
    }
}

.login-box .user-box {
    position: relative;
}

.user-box textarea {
    width: 100%;
    border-color: orange;
    margin-top: 15px;
    color: rgb(0, 0, 0);
    background-color: transparent;

    &:focus,
    &:focus-visible {
        outline: none;
    }
}

.popup-message {
    color: #000 !important;
    font-size: 12px !important;
    top: -20px !important;
    font-weight: 600 !important;
}

.login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #000;
    // margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid orange;
    outline: none;
    background: transparent;
}

.user-box label {
    color: #000 !important;
    font-size: 12px !important;
    top: -20px !important;
    margin-top: 10px;
    font-weight: 600 !important;
}

// .login-box .user-box label {
//     position: absolute;
//     top: 0;
//     left: 0;
//     padding: 10px 0;
//     font-size: 16px;
//     color: #fff;
//     pointer-events: none;
//     transition: 0.5s;
// }

// .login-box .user-box input:focus ~ label,
// .login-box .user-box input:valid ~ label {
//     top: -20px;
//     left: 0;
//     color: #000;
//     font-weight: 600;
//     font-size: 12px;
// }

.login-box form a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 25px;
    letter-spacing: 4px;
}

.quote-popup {
    padding-top: 0px;
}

.login-box a:hover {
    background: orange;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px orange, 0 0 25px orange, 0 0 50px orange, 0 0 100px orange;
}

.login-box a span {
    position: absolute;
    display: block;
}

.login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, orange);
    animation: btn-anim1 1s linear infinite;
}

.mobile_div {
    display: flex;

    .form-select {
        background-color: transparent;
        height: 40px;
        width: 140px;
        border: none;

        &:focus {
            box-shadow: none;
        }
    }
}

.phone_label_text {
    color: #000 !important;
    font-size: 12px !important;
    top: -20px !important;
    margin-top: 10px;
    font-weight: 600 !important;
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%,
    100% {
        left: 100%;
    }
}

.login-box a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, orange);
    animation: btn-anim2 1s linear infinite;
    animation-delay: 0.25s;
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%,
    100% {
        top: 100%;
    }
}

.login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, orange);
    animation: btn-anim3 1s linear infinite;
    animation-delay: 0.5s;
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%,
    100% {
        right: 100%;
    }
}

.login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, orange);
    animation: btn-anim4 1s linear infinite;
    animation-delay: 0.75s;
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%,
    100% {
        bottom: 100%;
    }
}

#tsparticles {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.item.popup_loader {
    background: orange;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0px 20px orange, 0 0 25px orange, 0 0 50px orange, 0 0 100px orange;

    .sl-spinner2 {
        height: 35px;
        width: 70px;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 900px;
    }
}

@media (max-width: 767px) {
    .quote-popup-img {
        display: none;
        max-width: 500px;
    }

    .modal-dialog {
        max-width: 500px;
    }
}

@media (max-width: 575px) {
    .popup-icn {
        display: block !important;
        font-size: 30px;
    }

    .quote_wrap p {
        display: none;
    }

    .quote_wrap button {
        padding: 0px 10px !important;

        &:hover {
            opacity: 0.7;
        }
    }

    // .quote_wrap {
    //     right: -6px;
    //     top: 215px;
    // }
}

@media screen and (max-width: 1430px) {
    .site_main_head .menu_bar_div .desk_dropdown .desk_dropdown_col ul li .desk_dropdown_link svg {
        margin-right: 10px;
        display: none;
    }

    .site_main_head .menu_bar_div .desk_dropdown .desk_dropdown_col ul li .desk_dropdown_link {
        transform: translateX(0px);
        font-size: 13px;
    }

    .site_main_head .menu_bar_div .desk_dropdown .container .row .tab-content .desk_dropdown_link svg {
        margin-right: 10px;
        display: none;
    }

    .site_main_head .menu_bar_div .desk_dropdown .container .row .tab-content .desk_dropdown_link {
        transform: translateX(0px);
        font-size: 13px;
    }
}

// ===================== // Quote area ===================


@media screen and (min-width: 1023px) and (max-width:1300px) {
    .site_main_head .menu_bar_div .nav_bar .main_nav .main_nav_list .main_nav_ul .main_nav_link.main_nav_Drop_link {
        padding: 10px;
        font-size: 15px;
    }

    .site_main_head .menu_bar_div .nav_bar .main_nav .site_logo .site_logo_img img {
        width: 170px;
        padding: 4px 0;
    }
}