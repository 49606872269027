.services_new {
    .LaunchPlatform-wrap {
        background: #0F1013;
        background-image: url('../../../media/new-landing/grains.png');

        .h4_title {
            // display: none;
            color: #fff;
            font-family: 'Syne', sans-serif;
            

        }

        .h2_title {
            color: #fff;
            font-family: 'Syne', sans-serif;
            @media screen and (min-width:280px) and (max-width:991px) {
                font-size: 35px;
            }
        }

        .row {


            background-image: url('../../../media/new-landing/services.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            position: relative;

            .flip-card-inner {
                background-color: #1a1a1a;
                border: 1px solid #5252526e;
            }

        }

    }
}