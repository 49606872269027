.press_hero_section {
    height: 530px;
    width: 100%;
    // padding: 300px;
    background-image: url("../../../media/event-bg-left.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .press_hero_heading {
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        font-size: 35px;
        font-weight: 500;
        color: #FFFFFF;
        text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.56);
        padding: 40px;
    }
}

.press_hero_content {
    margin-top: -371px;

    .press_event_item {
        box-shadow: 0 0 14px 0 rgba(74,74,74,0.20);
        border-radius: 14px;
        background-color: #fff;
        padding: 40px;
        height: 450px;
        margin: 15px 0px;
        background-image: url("../../../media/bg-shap.png");
        background-size: contain;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-position-x: right;
        cursor: pointer;

        .event_batch {
            background-color: #F58634;
            color: #fff;
            border-radius: 2px;
            font-size: 15.5px;
            font-weight: 700;
            width: fit-content;
            padding: 3px 12px;
            font-family: pressPara;
        }

        .event_title {
            padding-top: 12px;
            
                font-size: 39px;
            font-weight: 700;
            letter-spacing: 0.246049px;
            color: #373668;
            font-family: pressfont;
           
        
        }

        .event_desc {
            position: relative;
            letter-spacing: 0.246049px;
            color: #36363F;
            font-size: 17px;
            font-weight: 500;
            padding-left: 20px;
            font-family: pressPara;

            &::before {
                content: '';
                position: absolute;
                width: 100px;
                height: 78px;
                bottom: 0;
                left: 0;
                top: 0;
                border-left: 3px solid #f58741;
                background-image: linear-gradient(90deg, #f586341c 0%, rgba(245, 134, 52, 0) 100%);
            }
        }
        .event_subDesc{
            p{
                letter-spacing: 0.246049px;
                color: #36363F;
                font-size: 17px;
                font-weight: 500;
                padding: 20px 0px;
                font-family: pressPara;   
            }
        }

        .event_date {
            letter-spacing: 0.246049px;
            color: #2D2E67;
            font-size: 19px;
            font-weight: 500;
            padding-top: 12px;
            font-family: pressPara;
            position: relative;
            padding-left: 23px;
            &::before {
                content: '';
                position: absolute;
                width: 14px;
                height: 14px;
                left: 0;
                top: 20px;
                background-color: #f58741;
                border-radius: 50%;
                border: 3px solid #000545;
            }
        }
        .event_location{
            letter-spacing: 0.246049px;
            color: #2D2E67;
            font-size: 19px;
            font-weight: 500;
            font-family: pressPara; 
            position: relative;
            padding-left: 23px;
            padding-top: 10px;
            &::before {
                content: '';
                position: absolute;
                width: 14px;
                height: 14px;
                left: 0;
                top: 17px;
                background-color: #f58741;
                border-radius: 50%;
                border: 3px solid #000545;
            }
        }
    }

    .press_right_img {
        width: 100%;
        height: 450px;
        // box-shadow: 0px 12px 33px rgba(0, 0, 0, 0.12);
        border-radius: 14px;
        margin-top: 15px;
        box-shadow: 0 0 14px 0 rgba(74,74,74,0.20);
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            // box-shadow: 0px 12px 33px rgba(0, 0, 0, 0.12);
            border-radius: 14px;
            // box-shadow: 0 0 14px 0 rgba(74,74,74,0.20);
        }
    }
}

@media screen and (max-width:424px) {
    .press_hero_content .press_event_item {
        padding: 12px;
    }

    .press_hero_content .press_event_item .event_title {
        font-size: 27px;
    }

    .press_hero_content .press_event_item .event_desc {
        font-size: 15px;
    }

    .press_hero_content .press_event_item .event_date {
        font-size: 15px;
    }
    .press_hero_content .press_event_item .event_location {
        font-size: 15px;
        padding-top: 13px;
    }
    .press_hero_content .press_event_item .event_subDesc p {
        font-size: 15px;
        padding: 5px 0px;
    }
}
@media (min-width:425px) and (max-width:767px) {
    .press_hero_content .press_event_item {
        padding: 32px;
    }

    .press_hero_content .press_event_item .event_title {
        font-size: 32px;
    }

    .press_hero_content .press_event_item .event_desc {
        font-size: 17px;
    }

    .press_hero_content .press_event_item .event_date {
        font-size: 17px;
        padding-top: 0px;
        position: relative;
         &::before{
            position: absolute;
            top: 7px;
         }
    }
    .press_hero_content .press_event_item .event_location {
        font-size: 17px;
    }
    .press_hero_content .press_event_item .event_subDesc p {
        font-size: 17px;
        padding: 20px 0px;
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .press_hero_content .press_event_item {
        padding: 19px;
    }

    .press_hero_content .press_event_item .event_title {
        font-size: 29px;
    }

    .press_hero_content .press_event_item .event_desc {
        font-size: 16px;
    }

    .press_hero_content .press_event_item .event_date {
        font-size: 16px;
        padding-top: 0px;
    }
    .press_hero_content .press_event_item .event_location {
        font-size: 16px;
    }
    .press_hero_content .press_event_item .event_subDesc p {
        font-size: 15px;
        padding: 10px 0px;
    }
}

@media (min-width:1024px) and (max-width:1199px) {
    .press_hero_content .press_event_item {
        padding: 28px;
    }

    .press_hero_content .press_event_item .event_title {
        font-size: 34px;
    }

    .press_hero_content .press_event_item .event_desc {
        font-size: 16px;
    }

    .press_hero_content .press_event_item .event_date {
        font-size: 17px;
        padding-top: 0px;
    }
    .press_hero_content .press_event_item .event_location {
        font-size: 17px;
    }
    .press_hero_content .press_event_item .event_subDesc p {
        font-size: 16px;
        padding: 10px 0px;
    }
}

@media (min-width:1200px) and (max-width:1399px) {
    .press_hero_content .press_event_item {
        padding: 34px;
    }

    .press_hero_content .press_event_item .event_title {
        font-size: 35px;
    }

    .press_hero_content .press_event_item .event_desc {
        font-size: 18px;
    }

    .press_hero_content .press_event_item .event_date {
        font-size: 19px;
        padding-top: 0px;
    }
}