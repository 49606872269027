.technology_section {

    .head {
        text-align: center;
        margin-bottom: 2em;

        h2 {
            font-weight: 900;
        }
    }

    .nav-tabs {
        justify-content: center;
    }

    .technology_content {

        .nav-tabs {
            .nav-item {

                .nav-link.active {
                    color: #000;
                }

                button {
                    color: #c1c1c1;
                }
            }
        }

        .tech_div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 1em 0;

            h6 {
                color: #000;
            }

            img {
                width: 50px;
                height: 50px;
                margin: 1em;
            }
        }
    }
}