.allCases {
    padding: 2em 0em;

    .tags_wrapper {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #ededed;
    padding-bottom: 17px;

        .tag {
            background-color: whitesmoke;
            border: 1px solid #d4d4d4;
            padding: 0.2em 1em;
            border-radius: 25px;
            color: #8e8e8e;
        }

        .tag.active {
            background-color: #000;
            color: #d4d4d4;
        }

        .prevBtn {
            background: none;
            border: none;
            padding: 0 1em;
            width: 5%;
        }

        .nextBtn {
            width: 5%;
            background: none;
            border: none;
            padding: 0 1em;
        }

        .owl-carousel {
            width: 90%;
            margin-left: -40px;
        }

        .item {
            display: flex;
            justify-content: center;
        }
    }

    .case_box {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 5px;
        margin: 2em 0;
        overflow: hidden;
        position: relative;
        transition: all .5s;

        .case_image {
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:hover~.view_details {
                top: 0%;
            }
        }

        .case_content {
            padding: 1em;

            h4 {
                font-weight: 900;
            }

            p {
                font-size: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .case_tag_wrapper {
                margin-top: .5em;
                display: flex;
                flex-wrap: wrap;

                .case_tags {
                    border: 1px solid #ccc;
                    border-radius: 25px;
                    padding: .1em .5em;
                    background-color: #efefef;
                    font-size: 14px;
                    margin: 0 .4em;
                    color: #8e8e8e;
                }
            }

        }

        .view_details {
            background-color: rgba(0, 0, 0, 0.8);
            width: 100%;
            position: absolute;
            top: -100%;
            left: 0%;
            color: #efefef;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
        }

        &:hover .view_details {
            top: 0%;
        }
    }

    .case_pagination_div {
        display: flex;
        justify-content: center;

        .prev_btn {
            background-color: #00b4d8;
            border: 1px solid #ffffff;
            padding: 0.2em 1em;
            border-radius: 25px;
            color: #ffffff;
            margin: 0 1em;

            &:hover{
                background-color: #ffffff;
                color: #00b4d8;
                border-color: #00b4d8;
            }

            &:disabled{
                background-color: #8e8e8e;
                color: #ffffff;
                border-color: #8e8e8e;
            }
        }

        .next_btn {
            background-color: #00b4d8;
            border: 1px solid #ffffff;
            padding: 0.2em 1em;
            border-radius: 25px;
            color: #ffffff;
            margin: 0 1em;

            &:hover{
                background-color: #ffffff;
                color: #00b4d8;
                border-color: #00b4d8;
            }

            &:disabled{
                background-color: #8e8e8e;
                color: #ffffff;
                border-color: #8e8e8e;
            }
        }

    }
}

@media screen and (max-width: 767px) {

    .allCases .tags_wrapper .nextBtn,
    .allCases .tags_wrapper .prevBtn {
        padding: 0;
    }
}