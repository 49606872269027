.form-wrap {
    // padding: 50px 0px;
    padding: 5em 0em;
    // background-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4)), url("../../../media/contact1.jpg");
    background-image: url("../../../media/contact1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    padding-bottom: 7em;
    position: relative;

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        left: 0px;
        bottom: 0px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4));

    }

    .contact-details {
        float: right;

        .h5_title {
            color: #fff;
            font-size: 20px;
            margin: 15px 0px 0px;
        }

        a {

            &:hover {
                color: #fff;
            }
        }

        p {
            // border-bottom: 2px solid orange;
            // font-size: 16px;
            // padding-bottom: 10px;
        }
    }

    .contact-form-heading {
        display: flex;
        justify-content: center;
        margin-bottom: 3em;
        position: relative;
        z-index: 1;

        .contact_form_head_div {
            text-align: center;
            width: 80%;

            .h2_title {
                // padding-bottom: 20px;
                // border-left: 5px solid #ff9800;
                padding-left: 10px;
                // margin-bottom: 1em;
            }
        }

        p {
            color: #000;
        }
    }

    button {
        margin-top: 20px;
        background: transparent;
        padding: 10px 45px;
        border: 1px solid #36bbc2;
        position: relative;
        overflow: hidden;
        transition: all 0.2s;
        z-index: 0;
        border-radius: 25px;
        color: #000;

        &:hover {
            color: #fff;
            border-color: #36bbc2;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: 10px;
            height: 10px;
            background-image: linear-gradient(45deg, #79dee3, #36bbc2, #79dee3);
            border-radius: 50%;
            border: 1px solid #36bbc2;
            transition: all 0.2s;
            z-index: -1;
        }

        &:hover::before {
            transform: scale(160);
        }

    }

    .btn:hover {
        background-color: #36bbc2;
    }

    .contact-form-wrap {
        .mobile_div {
            display: flex;

            .form-select {
                background-color: transparent;
                height: 40px;
                width: 140px;
                border: none;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        label {
            font-size: 14px;
            color: grey;
        }

        .input_field {
            // margin-bottom: 50px;
            color: #47576a;

            &::placeholder {
                color: lightgrey;
            }

            &:focus {
                border-bottom: 1px solid #00b4d8 !important;
            }

            &::-webkit-inner-spin-button {
                display: none;
            }
        }
    }

    .get_in_touch_div {
        position: relative;
        z-index: 1;

        .get_in_touch_row {
            backdrop-filter: blur(4px);
            background: rgb(255 255 255 / 82%);
            box-shadow: 0px 0px 20px lightgrey;
            padding: 2em;
            border-radius: 20px;

            .contact_information {
                height: 100%;
                display: flex;
                flex-direction: column;
                background-color: #00b4d8;
                border-radius: 20px;
                padding: 2em;
                position: relative;
                overflow: hidden;
                z-index: 9;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -5%;
                    right: -3%;
                    width: 150px;
                    height: 150px;
                    background-color: #00a3c3;
                    border-radius: 50%;
                    z-index: -7;
                }

                &::after {
                    position: absolute;
                    background: #ffffff78;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    content: "";
                    right: 40px;
                    bottom: 30px;
                }

                .head {
                    h3 {
                        color: #fff;
                    }

                    p {
                        color: #000545;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .contact-form-wrap {
        margin-top: 20px;
    }

    .form-wrap .get_in_touch_div .get_in_touch_row {
        padding: 1em;
        margin: 0px 15px;
    }

    .form-wrap .contact-form-heading .contact_form_head_div {
        width: 100%;
    }
}