.app-footer {
    background-color: #fafafb;
    // padding: 15px 0px;

    p {
        color: #4b5a65;
        text-align: center;
        padding: 15px;
        border-top: 1px solid #d8d8d8;
    }

    .clt_and_gfr_img_wrap {
        .clt_and_gfr_img {
            margin-bottom: 20px;
        }
    }


    .bottom_footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .cdn_class {
            display: flex;

            h4 {
                font-weight: 600;
                color: #000545;
                line-height: 1;

                span {
                    font-size: 14px;
                }
            }
        }
    }

}

.bottom_logos {
    display: flex;

    .goodfirm-widget {
        margin-top: -20px;
    }
}

@media screen and (max-width:768px) {
    .bottom_logos {
        display: flex;
        flex-wrap: wrap;

        .goodfirm-widget {
            margin-top: -2px;
            margin-left: -40px;
        }
    }
}

//================= top btn =======================
.top-btn-wrap {
    position: fixed;
    bottom: 30px;
    left: 30px;
    // border: 1px solid #47576a;
    background-color: #fff;
    box-shadow: 0px 0px 5px #47576a;
    // animation-iteration-count: infinite;
    // animation-name: line;
    // animation-duration: 4s;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    transition: all 0.5s;
    overflow: hidden;
    z-index: 1;

    button {
        color: #47576a;
        font-size: 25px;
        line-height: 40px;
        padding: 0px;
        padding-left: 9px;
        border: none;

        &:hover {
            color: #fff;
            border: none;
        }
    }

    &::before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: -10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #1b2b38;
        transition: all 0.5s;
        z-index: -1;
    }

    &:hover::before {
        transform: scale(20);
    }

    &:hover>button {
        color: #fff;
    }
}

@keyframes line {
    0% {
        background-color: red;
    }

    25% {
        background-color: pink;
    }

    50% {
        background-color: blue;
    }

    75% {
        background-color: orange;
    }

    100% {
        background-color: green;
    }
}

//================= // top btn ====================

// ================================ chandrakant footer section start ===============================
.footer_section {
    // box-shadow: 0px -5px 50px 15px #e7e7e7;
    // border-top-left-radius: 60px;
    // border-top-right-radius: 60px;
    // margin-top: 100%;
    // margin-top: -2.5em;
    // padding: 3em 0em;
    background-color: #fafafb;

    .footer_head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0px;
        margin-top: 1em;

        .subscribe_fieldDiv {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .subscribe_text {
                font-size: 1.8em;
                color: #47576a;
                font-weight: 500;
            }

            .subscribe_email_field {
                margin-left: 20px;
                display: flex;

                input {
                    border: none;
                    background-color: #f1f1f1;
                    // box-shadow: -4px 2px 6px #c7c7c782;
                    padding: 0px 15px;
                    outline: none;
                    color: #47576a;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    height: 3em;
                }

                button {
                    border: none;
                    background-color: #ededed;
                    // box-shadow: 4px 2px 6px #c7c7c782;
                    padding: 0px 15px;
                    margin-left: -10px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    outline: none;
                    overflow: hidden;
                    position: relative;
                    transition: all 0.5s;
                    z-index: 0;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0%;
                        left: -100%;
                        width: 100%;
                        height: 100%;
                        background-color: #17243b;
                        transition: all 0.2s;
                        z-index: -1;
                    }

                    &:hover::before {
                        left: 0%;
                    }

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }

        .social_media_links {
            width: 20%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            a {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    font-size: 1.1em;
                }

                &:hover {
                    transform: translateY(-10px) scale(1.2);
                }
            }
        }
    }

    .foot_sec {
        margin: 20px 0px;

        .foot_sec_subhead {
            font-size: 1.2em;
            font-weight: bold;
            color: #1b2b38;
            border-bottom: 1px solid #4b5a6530;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }

        ul {
            line-height: 2;

            li {
                overflow: hidden;

                a {
                    color: #000;
                    transition: all 0.5s;
                    position: relative;
                    // transform: translateX(0px);
                    // overflow: hidden;

                    &:hover {
                        padding-left: 1em;
                        background: -webkit-linear-gradient(90deg,
                                rgba(206, 0, 255, 1) 0%,
                                rgba(9, 9, 121, 1) 44%,
                                rgba(0, 212, 255, 1) 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        // transform: translateX(10px);
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0%;
                        left: 0%;
                        width: 100%;
                        height: 1px;
                        background: linear-gradient(90deg,
                                rgba(206, 0, 255, 1) 0%,
                                rgba(9, 9, 121, 1) 44%,
                                rgba(0, 212, 255, 1) 100%);
                        transition: all 0.5s;
                        transform: translateX(-100%) translateY(5px);
                    }

                    &:hover::before {
                        // width: 100%;
                        transform: translateX(0%) translateY(5px);
                    }
                }
            }
        }
    }

    .footer_bottom {
        display: flex;
        justify-content: space-between;
        padding: 2em;

        // .logo{
        //     background: -webkit-linear-gradient(90deg, rgba(206,0,255,1) 0%, rgba(9,9,121,1) 44%, rgba(0,212,255,1) 100%);
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: transparent;
        // }

        .security_div {
            a {
                margin-right: 2em;
            }
        }
    }
}

// ================================ chandrakant footer section end ===============================
// ================================= footer address ============================
.footer_address {
    .address-wrap {
        .row {
            align-items: initial;
        }
    }

    .card-body {
        padding: 0px;
    }

    .address-title {
        display: none;
    }

    .address-wrap .ind-address-wrap .card {
        background-color: transparent;
        box-shadow: none;
    }

    .address-wrap .card {
        box-shadow: none;
        height: auto;
        position: relative;
        border-radius: 0px;
        background-color: transparent;
    }

    .address-wrap {
        padding: 20px 0px;
    }

    .sub_address {
        .row {
            align-items: inherit;
        }

        .card {
            margin-bottom: 5px;

            .card-body {
                padding: 0px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .footer_section .footer_head {
        justify-content: center;
    }

    .footer_section .footer_bottom {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .footer_section .footer_head .subscribe_fieldDiv {
        justify-content: center;
    }

    .footer_section .footer_head .subscribe_fieldDiv .subscribe_email_field {
        margin-left: 0%;
        width: 100%;
    }

    .footer_section .footer_head .subscribe_fieldDiv .subscribe_email_field input {
        width: 100%;
    }

    .footer_section .footer_head .social_media_links {
        width: 100%;
        margin-top: 1em;
    }
}

// =========================================== WhatsAPP =================================
.whats_app_div {
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 5px grey;
    position: fixed;
    bottom: 50%;
    left: 1.6%;
    border-radius: 50%;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-color: #44c860;
        border-radius: 50%;
        // transform: scale(1);
        // opacity: 0;
        transition: all 0.5s;
        animation: demo 1s linear infinite;
        z-index: -1;
    }

    @keyframes demo {
        0% {
            transform: scale(0);
            opacity: 1;
        }

        100% {
            transform: scale(1.7);
            opacity: 0;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-color: #44c860;
        border-radius: 50%;
        // transform: scale(1);
        // opacity: 0;
        transition: all 0.5s;
        animation: demo1 1s linear 1s infinite;
        z-index: -1;
    }

    @keyframes demo1 {
        0% {
            transform: scale(0);
            opacity: 1;
        }

        100% {
            transform: scale(2);
            opacity: 0;
        }
    }
}

@media screen and (max-width: 575px) {
    .footer_section .foot_sec ul li a {
        font-size: 14px;
    }

    .footer_section .footer_head .subscribe_fieldDiv .subscribe_text {
        font-size: 22px;
    }
}



// ============================= event popup ==========================================





// .modal-header {
//     border-bottom: none !important; 
// }

// .event_popup_modal.modal-body {
//     padding: 2em;
//     padding-bottom: 3em;
// }



// .calender-icon {
//     color: purple;
//     font-size: 18px;
// }








.event_modal_div {
    .event_header {
        padding: 0px;
        border-bottom: none !important;

        button {
            margin-bottom: -3em;
            z-index: 1;
            margin-right: 10px;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .event_popup_modal {
        background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1)), url('../../images/event/dubai1.png');
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        padding: 2em;
        padding-bottom: 3em;
        position: relative;

        .north_img {
            position: absolute;
            top: 20px;
            right: 40px;

            img {
                width: 150px;
            }
        }

        .know-btn {
            width: 160px;
            height: 45px;
            border-radius: 50px;
            background-color: #fff;
            border: 1px solid #00a5bb;
            color: #00a5bb;
            margin-right: 1em;
            padding: 10px 20px;

            &:hover {
                background-color: #00a5bb;
                color: #fff;
                border: 1px solid #00a5bb;
            }
        }

        .meet-btn {
            border-radius: 50px;
            background-color: #00a5bb;
            border: none;
            color: white;
            display: inline-block;
            text-align: center;
            padding: 10px 20px;
        }

        .calender_div {
            display: flex;
            align-items: center;
        }

        .calender_div img {
            width: 40px;
            margin-right: 10px;
        }

        .Meet-head2 {
            width: 600px;
            font-size: 46px;
            color: #000545;

        }

        .Pod-head4 {
            font-weight: 600;
            font-family: 'Muli', sans-serif;
        }

        .num-content {
            font-size: 18px;
            font-family: 'Muli', sans-serif;
            font-weight: 600;
        }

        .meetmepara {
            width: 600px;
            font-size: 1.2em;
        }

        .availabe-for {
            font-size: 1.2em;
        }
    }

    // .event_popup_modal.modal-body {
    // background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1)), url('../../images/event/dubai.jpg');
    // background-repeat: no-repeat;
    // background-size: cover;
    // border-radius: 10px;
    // }
}



/* ............................................Responsive.............................. */

@media screen and (max-width:768px) {
    .event_modal_div .event_popup_modal .Meet-head2 {
        width: auto;
        font-size: 1.4em;
        font-weight: 700;
    }

    .event_modal_div .event_popup_modal .north_img img {
        width: 100px;
    }

    .event_modal_div .event_popup_modal .north_img {
        top: 70px;
        right: 0px;
    }

    .event_modal_div .event_popup_modal .meetmepara {
        width: auto;
        font-size: 16px;
    }

    .event_modal_div .event_popup_modal .availabe-for {
        font-size: 16px;
    }

    .event_modal_div .event_popup_modal .know-btn {
        margin-bottom: 10px;
    }


}

@media screen and (max-width:514px) {
    .event_modal_div .event_popup_modal .know-btn {
        width: 100%;
        display: inline-block;
        text-align: center;
    }

    .event_modal_div .event_popup_modal .meet-btn {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .event_modal_div .event_popup_modal .Meet-head2 {
        margin-top: 45px;
    }

    .event_modal_div .event_popup_modal .north_img {
        top: 0px;
        left: 15px;
    }
}

// ============================= event popup ==========================================






//          ==================== our group  logo's  ================= 


.r_group_container {
    margin-top: 6rem;
    margin-bottom: 3rem;

    h2 {
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
    }

    .r_group_logos {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        padding-top: 1rem;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        .logo {
            width: 100%;
            max-height: 80px;
            object-fit: contain;
            border-radius: 5px;
            transition: transform 0.3s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    @media (max-width: 768px) {
        .r_group_logos {
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            gap: 15px;

            .logo {
                max-height: 70px;
            }
        }
    }

    @media (max-width: 480px) {
        .r_group_logos {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;

            .logo {
                max-height: 60px;
            }
        }
    }
}