.GameSolution-wrap {
    padding: 50px 0px;
    position: relative;
    background-image: url("../../../../images/trusted/game.png");
    background-repeat: no-repeat;
    // background-position: right;
    background-position: inherit;
    background-size: cover;

    .row {
        align-items: center;
    }

    .GameSolution-img {
        img {
            animation: top 3s ease-in-out infinite;
        }
    }

    .GameSolution-text {
        .h3_title {
            color: #36bbca;
        }

        p {
            padding: 10px 0px;
        }
    }

    a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 16px;
        display: inline-block;
        margin-top: 30px;
    }

    a span {
        padding: 8px;
        transition: .5s;
        position: relative;
    }

    a span:nth-child(1) {
        color: #fff;
        background: #262626;
    }

    a span:nth-child(2) {
        color: #fff;
        background: #36bbca;
    }

    a span:nth-child(1):before {
        content: attr(data-attr);
        position: absolute;
        top: 0;
        left: 0;
        background: #36bbca;
        padding: 8px;
        transition: 0.5S;
        transform-origin: top;
        transform: rotateX(90deg) translateY(-50%);
    }

    a:hover span:nth-child(1):before {
        transform: rotateX(0deg) translateY(0%);
    }

    a span:nth-child(2):before {
        content: attr(data-attr);
        position: absolute;
        top: 0;
        left: 0;
        background: #262626;
        padding: 8px 15px;
        transition: 0.5S;
        transform-origin: bottom;
        transform: rotateX(90deg) translateY(50%);
    }

    a:hover span:nth-child(2):before {
        transform: rotateX(0deg) translateY(0%);
    }

    a span:nth-child(1):after {
        content: attr(data-attr);
        padding: 8px;
        position: absolute;
        top: 0;
        left: 0;
        background: #262626;
        transform-origin: bottom;
        transform: rotateX(0deg) translateY(0%);
        transition: 0.5s;
    }

    a:hover span:nth-child(1):after {
        transform: rotateX(90deg) translateY(50%);
    }

    a span:nth-child(2):after {
        content: attr(data-attr);
        position: absolute;
        top: 0;
        left: 0;
        background: #36bbca;
        padding: 8px 15px;
        transition: 0.5S;
        transform-origin: top;
        transform: rotateX(0deg) translateY(0%);
    }

    a:hover span:nth-child(2):after {
        transform: rotateX(90deg) translateY(-50%);
    }
}

@keyframes top {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 767px) {
    .GameSolution-wrap .container {
        max-width: 100%;
    }
}

@media screen and (max-width: 575px) {
    .GameSolution-wrap {
        padding: 20px 0px;
    }
}