.ourGoal_section {
    text-align: center;
    background-image: linear-gradient(45deg, rgb(27, 0, 54), rgb(39, 3, 22));
    background-size: 200% 200%;
    padding: 5em 0em;
    animation: back 2s linear infinite;

    @keyframes back {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }


    .h2_title {
        color: #fff;
    }

    .h4_title {
        color: #fff;
    }

    p {
        width: 60%;
        margin: auto;
        color: #fff;
    }
}

@media screen and (max-width: 767px) {
    .ourGoal_section p {
        width: 100%;
        padding: 0px 15px;
    }
}
@media screen and (max-width: 575px) {
    .ourGoal_section{
        padding: 1em 0em;
    }
}