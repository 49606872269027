@import url('./heroSection.scss');
@import url('./launchPlatform.scss');
@import url('./partner_section.scss');
@import url('./startSomething.scss');
@import url('./technology.scss');
@import url('./blog_section.scss');
@import url("./OnlineStore.scss");
@import url("./testimonial.scss");
@import url("./ContactUs.scss");
@import url('./side_box_section.scss');
@import url('./whychoose.scss');


.keyPoint_section {
    padding: 4em 0;

    .keyPoint_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid gray;
        border-radius: 15px;
        padding: 2em 0;
        margin: 1em 0;

        .value {
            font-weight: bold;
            font-size: 1.5em;
        }

        .text {}
    }
}

.software-service-box {
    background: linear-gradient(90deg, #020710 0.21%, #141921 100%);

    .software-service-box-title {
        p {
            text-align: center;
            font-size: 23px;
            padding-top: 50px;
            color: #fff;
            max-width: 700px;
            margin: 0px auto;
        }
    }

    .keyPoint_section {
        padding: 4em 0;

        .keyPoint_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid gray;
            border-radius: 15px;
            padding: 2em 0;
            margin: 1em 0;

            .value {
                font-weight: bold;
                font-size: 1.5em;
                color: #fff;
            }

            .text {
                color: #fff;
            }
        }
    }

}