.event_detail_section {
    padding: 70px 0px;

    .event_head_content {
        padding: 20px 65px 20px 20px;

        .event_batch {
            background-color: #F58634;
            color: #fff;
            border-radius: 2px;
            font-size: 15.5px;
            font-weight: 700;
            width: fit-content;
            padding: 3px 12px;
            margin-bottom: 10px;
            font-family: pressPara;
        }

        .event_title {
            font-size: 39px;
            font-weight: 700;
            letter-spacing: 0.246049px;
            color: #373668;
            font-family: pressfont;
            padding: 21px 0px;
            line-height: 1.3;
        }

        .event_desc {
            position: relative;
            letter-spacing: 0.246049px;
            color: #36363F;
            font-size: 17px;
            font-weight: 500;
            padding-left: 20px;
            font-family: pressPara;

            &::before {
                content: '';
                position: absolute;
                width: 100px;
                height: 78px;
                bottom: 0;
                left: 0;
                top: 0;
                border-left: 3px solid #f58741;
                background-image: linear-gradient(90deg, #f586341c 0%, rgba(245, 134, 52, 0) 100%);
            }
        }

        .event_date {
            letter-spacing: 0.246049px;
            color: #2D2E67;
            font-size: 18px;
            font-weight: 500;
            padding-top: 60px;
            font-family: pressPara;
            color: #949494;
            position: relative;
            padding-left: 23px;
            &::before {
                content: '';
                position: absolute;
                width: 14px;
                height: 14px;
                left: 0;
                top: 66px;
                background-color: #f58741;
                border-radius: 50%;
                border: 3px solid #000545;
            }
        }
        .event_location{
            letter-spacing: 0.246049px;
            color: #949494;
            font-size: 19px;
            font-weight: 500;
            padding-top: 10px;
            font-family: pressPara; 
            position: relative;
            padding-left: 23px;
            
            &::before {
                content: '';
                position: absolute;
                width: 14px;
                height: 14px;
                left: 0;
                top: 17px;
                background-color: #f58741;
                border-radius: 50%;
                border: 3px solid #000545;
            }
        }
    }

    .event_img {
        width: 100%;
        height: 470px;
        border-radius: 7.87053px;

        img {
            border-radius: 7.87053px;
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }

    .event_details {
        padding: 50px 30px;

        .event_detail_content {
            padding: 0px 15px;
            font-family: pressPara !important;

            p {
                color: #373668;
                font-size: 17px;
                font-weight: 500;
                font-family: pressPara;
            }

            h1 {
                color: #373668;
                font-family: pressPara;
            }

            h2 {
                color: #373668;
                font-family: pressPara;
            }

            h3 {
                color: #373668;
                font-family: pressPara;
            }

            h4 {
                color: #373668;
                font-family: pressPara;
            }

            h5 {
                color: #373668;
                font-family: pressPara;
            }

            h6 {
                color: #373668;
                font-family: pressPara;
            }
        }

        .other_events {
            position: sticky;
            top: 10%;
            padding: 0px 20px;

            .head {
                text-align: center;
                font-weight: 600;
                font-size: 25px;
                color: #373668;
                font-family: pressfont;
                padding: 15px;
            }

            .other_events_items {
                margin-top: 25px;
                background: #FFFFFF;
                box-shadow: 0px 15px 46px rgba(0, 0, 0, 0.1);
                border-radius: 7.87053px;

                .other_events_img {
                    img {
                        border-top-left-radius: 7.87053px;
                        border-top-right-radius: 7.87053px;
                    }
                }

                .other_events_body {
                    padding: 15px;

                    .title {
                        font-size: 19px;
                        font-weight: 700;
                        color: #232325;
                        font-family: pressfont;
                    }

                    .desc {
                        font-family: pressPara;
                        font-weight: 500;
                        font-size: 12px;
                        color: #232325;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .event_detail_section .event_head_content {
        padding: 20px;
    }

    .event_detail_section .event_head_content .event_title {
        font-size: 30px;
    }

    .event_detail_section .event_img {
        height: 380px;
    }

    .event_detail_section .event_details .other_events {
        padding: 0px;
    }
}

@media (min-width:768px) and (max-width:1200px) {
    .event_detail_section .event_head_content {
        padding: 10px;
    }

    .event_detail_section .event_head_content .event_title {
        font-size: 30px;
        padding: 10px 0px;
    }

    .event_detail_section .event_head_content .event_desc {
        font-size: 16px;
        margin-top: 10px;
    }

    .event_detail_section .event_img {
        height: 380px;
    }

    .event_detail_section .event_head_content .event_date {
        padding-top: 38px;
        position: relative;
         &::before{
            position: absolute;
            top: 47px;
         }
    }

    .event_detail_section .event_details .other_events {
        padding: 0px;
    }
}