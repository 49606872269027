.achievements-wrap {
    padding: 50px 0px;
    .row{
        align-items: center;
    }
    .achievements-card {
        background-color: #fff;
        padding: 20px;
        box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
        margin-bottom: 15px;
        .h3_title{
            font-size: 35px;
        }
        img{
            width: 100px;
        }
    }
    .achievements-title{
        text-align: center;
        .h3_title{
            color: #36bbc2;
            padding-bottom: 20px;
        }
    }
    .achievements-img{
        width: 80%;
        margin: auto;
    }
}
@media (max-width: 767px) {
    .achievements-wrap .container {
        max-width: 100%;
    }
}
@media (max-width: 575px) {
    .achievements-wrap{
        padding: 20px 0px;
    }

    .achievements-wrap .row {
        flex-direction: column-reverse;
    }
    .achievements-wrap .achievements-title{
        margin: 0px;
    }
}