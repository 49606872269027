.map-wrap {
    padding: 50px 0px;

    iframe {
        border-radius: 10px;
        box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
    }
}

@media screen and (max-width: 575px) {
    .map-wrap {
        padding: 20px 0px;
    }
}