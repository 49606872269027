.hire_dev_section {
    padding: 3rem 0rem 10rem 0rem;
    background-image: url("../../../images/hire/banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:auto;
     @media (min-width:768px) and (max-width:991px){
        padding: 6rem 0rem 10rem 0rem;
     }

    .banner_content_wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        .banner_content {
            h2 {
                font-weight: bold;
                margin-bottom: 1em;
                position: relative;
                z-index: 0;
                // background: #000;
                color: #fff;

                &::before {
                    content: 'Service';
                    position: absolute;
                    top: -120%;
                    z-index: -1;
                    left: 0%;
                    width: fit-content;
                    height: 100%;
                    font-size: 2.1em;
                    font-weight: 900;
                    // -webkit-text-stroke: 4px;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #efefef52;
                    color: #00000000;
                    font-family:Verdana, Geneva, Tahoma, sans-serif;
                }
            }

            p {}

            button {
                margin-top: 20px;
                background: transparent;
                padding: 10px 45px;
                border: 1px solid #36bbc2;
                position: relative;
                overflow: hidden;
                transition: all 0.2s;
                z-index: 0;
                border-radius: 25px;
                color: #000;

                &:hover {
                    color: #fff;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -10px;
                    left: -10px;
                    width: 10px;
                    height: 10px;
                    background-image: linear-gradient(45deg, #79dee3, #36bbc2, #79dee3);
                    border-radius: 50%;
                    border: 1px solid #79dee3;
                    transition: all 0.2s;
                    z-index: -1;
                }

                &:hover::before {
                    transform: scale(60);
                }
            }
        }
    }

    .banner_image_wrapper {
        .banner_image {
            img {}
        }
    }
}