.AboutTrusted-wrap {
    padding: 50px 0px;

    .AboutTrusted-title {
        text-align: center;
        max-width: 700px;
        margin: 0px auto 50px;
        .h3_title{
            color: #36ccb2;
        }
        p {
            padding-top: 20px;
        }
    }

    .AboutTrusted-card-wrap {
        min-height: 350px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        padding: 20px;
        border-radius: 10px;
        transition: all 0.5s ease;
        text-align: center;

        &:hover {
            transform: translateY(-8px);
        }

        .AboutTrusted-card-img img {
            width: 100px;
            margin: auto;
        }

        button {
            color: #36ccb2;
            margin-top: 10px;
            transition: all 0.3s ease;

            &:hover {
                opacity: 0.8;
            }
        }

        .h3_title {
            padding: 15px 0px;
        }
    }
}
@media screen and (max-width: 991px) {
    .AboutTrusted-card-wrap{
        margin-bottom: 20px;
    }
    .AboutTrusted-wrap{
        padding: 20px 0px;
    }
    .AboutTrusted-wrap .AboutTrusted-title{
        margin: 0px;
    }
}