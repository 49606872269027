.hero-wrap {
    // padding: 100px 0px 50px;
    // text-align: center;
    // background-image: url("https://www.kg-legal.eu/wp-content/uploads/2021/10/blockchain.gif");
    // background-repeat: no-repeat;
    // width: 100%;
    // background-size: cover;
    position: relative;
    // padding: 4em 0em;

    .hero_overlay {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(4px);
    }

    .heroSection_video {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    // &::after {
    //     background: #0000007a;
    //     content: "";
    //     position: absolute;
    //     left: 0px;
    //     top: 0px;
    //     height: 595px;
    //     width: 100%;
    // }
    &::after {
        // background: #fff;
        // opacity: 0.5;
        // position: absolute;
        // content: "";
        // height: 1200px;
        // width: 100%;
        // top: 0px;
        // left: 0px;
    }

    video {
        // margin-top: -74px;
        // position: relative;
    }

    .OwlCarousel-wrap {
        // margin-top: -750px;
        // position: absolute;
        // top: 25%;
        // width: 100%;

        .full-size-event-banner-uk {
            height: 570px;
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .event-banner-content {
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url('../../../media/event-bg-left.webp');
                background-repeat: no-repeat;
                background-size: cover;
                width: 50%;
            }

            .uk-banner-part-one {
                width: 70%;
                margin-top: -8em;

                .event-banner-tagline {
                    color: #fff;
                    font-size: 18px;
                    font-weight: bolder;
                    letter-spacing: 1px;
                    position: relative;
                    margin-bottom: .6em;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 90px;
                        bottom: -4px;
                        height: 4px;
                        background-color: #FD226F;
                        border-radius: 25px;
                    }
                }

                p {
                    color: #fff;
                    margin-bottom: 2em;
                    font-size: 15px;
                }

                a {
                    background-color: #FD226F;
                    border: 1px solid #fff;
                    color: #fff;
                    border-radius: 25px;
                    padding: .5em 1.5em;
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    transition: all .2s;

                    &:hover {
                        font-weight: bold;
                        background-color: #fff;
                        border: 1px solid #FD226F;
                        color: #FD226F;
                        // transform: scale(.9);
                        transform: translateX(10px);
                    }

                    svg {
                        margin-left: .5em;
                    }
                }

                h2 {
                    color: #fff;
                    font-size: 3em;
                    font-weight: bolder;
                }
            }

            .uk-banner-part-two {
                background-image: url('../../../media/uk-event-bg.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position-x: center;
                width: 50%;
                height: inherit;
            }

            .schedule-meeting-bar {
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                display: flex;
                flex-wrap: wrap;
                border-radius: 16px;
                padding: .3em 1em;
                width: clamp(500px, 80%, 600px);
                justify-content: space-evenly;
                align-items: center;
                // z-index: 1;

                &::before {
                    content: url('../../../media/spiral-line-arrow.svg');
                    position: absolute;
                    top: -20px;
                    right: 60px;
                    width: 0px;
                    z-index: -1;
                }

                .event-address {
                    display: flex;
                    color: #000;
                    font-weight: bold;
                    font-size: 16px;

                    img {
                        width: 20px;
                        margin-right: .5em;
                    }
                }

                .event-date {
                    display: flex;
                    color: #000;
                    font-weight: bold;
                    font-size: 16px;
                    width: 35%;
                    line-height: 1;

                    img {
                        width: 32px;
                        margin-right: .5em;
                    }
                }

                .schedule-meeting-link {
                    width: 170px;
                    background-color: #011E3F;
                    color: #fff;
                    font-size: 16px;
                    padding: .6em 1.5em;
                    border-radius: 7px;
                    line-height: 1.2;
                }
            }
        }

        .full-size-event-banner-blockchain {
            height: 600px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            background-image: url(https://img.freepik.com/free-photo/rpa-concept-with-blurry-hand-touching-screen_23-2149311914.jpg?t=st=1713948075~exp=1713951675~hmac=dcc4c686fe4dac9ca0e2894f6e4a253685e243298518a46bd34391eefc99795b&w=740);
            background-size: cover;
            background-repeat: no-repeat;

        }

        // .full-size-event-banner-usa {
        //     min-height: 600px;
        //     display: flex;
        //     position: relative;
        //     background-image: url('../../../media/bg-chicago-banner-img.png');
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     background-position: center;
        //     width: 100%;

        //     .event-banner-content {
        //         height: inherit;
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         background-image: url('../../../media/event-bg-left.webp');
        //         background-repeat: no-repeat;
        //         background-size: cover;
        //         width: 50%;
        //     }

        //     .au-banner-part-one {
        //         width: 70%;
        //         margin-top: -8em;


        //         .event-banner-tagline {
        //             color: #fff;
        //             font-size: 18px;
        //             font-weight: bolder;
        //             letter-spacing: 1px;
        //             position: relative;
        //             margin-bottom: .6em;

        //             &::before {
        //                 content: '';
        //                 position: absolute;
        //                 width: 90px;
        //                 bottom: -4px;
        //                 height: 4px;
        //                 background-color: #FD226F;
        //                 border-radius: 25px;
        //             }
        //         }

        //         p {
        //             color: #fff;
        //             margin-bottom: 2em;
        //             font-size: 15px;
        //         }

        //         a {
        //             background-color: #FD226F;
        //             border: 1px solid #fff;
        //             color: #fff;
        //             border-radius: 25px;
        //             padding: .5em 1.5em;
        //             display: flex;
        //             align-items: center;
        //             width: fit-content;
        //             transition: all .2s;

        //             &:hover {
        //                 font-weight: bold;
        //                 background-color: #fff;
        //                 border: 1px solid #FD226F;
        //                 color: #FD226F;
        //                 // transform: scale(.9);
        //                 transform: translateX(10px);
        //             }

        //             svg {
        //                 margin-left: .5em;
        //             }
        //         }

        //         h2 {
        //             color: #fff;
        //             font-size: 3em;
        //             font-weight: bolder;
        //         }
        //     }

        //     .australia,
        //     .au-banner-part-two,
        //     .du-banner-part-two,
        //     .singapore-banner-part-two,
        //     .america-banner-part-two,
        //     .first-banner-part-two,
        //     .second-banner-part-two,
        //     .third-banner-part-two,
        //     .forth-banner-part-two,
        //     .fifth-banner-part-two,
        //     .six-banner-part-two,
        //     .seven-banner-part-two,
        //     .eight-banner-part-two,
        //     .nine-banner-part-two,
        //     .ten-banner-part-two,
        //     .eleven-banner-part-two {
        //         background-image: url('../../../media/australia-event-bg.jpg');
        //         background-repeat: no-repeat;
        //         background-size: cover;
        //         background-position-x: center;
        //         width: 50%;
        //         height: inherit;
        //     }

        //     .du-banner-part-two {
        //         background-image: url('../../../media/dubai-event-bg.webp') !important;
        //     }

        //     .singapore-banner-part-two {
        //         background-image: url('../../../media/singapore-event.webp') !important;
        //     }

        //     .america-banner-part-two {
        //         background-image: url('../../../media/america-event.webp') !important;
        //     }

        //     .first-banner-part-two {
        //         background-image: url('../../../media/first.png') !important;
        //     }

        //     .australia {
        //         background-image: url('../../../media/australia.jpg') !important;
        //     }

        //     .second-banner-part-two {
        //         background-image: url('../../../media/second.png') !important;
        //     }

        //     .third-banner-part-two {
        //         background-image: url('../../../media/third.png') !important;
        //     }

        //     .forth-banner-part-two {
        //         background-image: url('../../../media/forth.png') !important;
        //     }

        //     .fifth-banner-part-two {
        //         background-image: url('../../../media/fifth.png') !important;
        //     }

        //     .six-banner-part-two {
        //         background-image: url('../../../media/six.png') !important;
        //     }

        //     .seven-banner-part-two {
        //         background-image: url('../../../media/seven.png') !important;
        //     }

        //     .eight-banner-part-two {
        //         background-image: url('../../../media/USA.png') !important;
        //     }

        //     .nine-banner-part-two {
        //         background-image: url('../../../media/nine.jpg') !important;
        //     }

        //     .ten-banner-part-two {
        //         background-image: url('../../../media/ten.jpg') !important;
        //     }

        //     .eleven-banner-part-two {
        //         background-image: url('../../../media/eleven.jpg') !important;
        //     }

        //     .schedule-meeting-bar {
        //         position: absolute;
        //         bottom: 5%;
        //         left: 50%;
        //         transform: translateX(-50%);
        //         background-color: #fff;
        //         display: flex;
        //         flex-wrap: wrap;
        //         border-radius: 16px;
        //         padding: .3em 1em;
        //         width: 35%;
        //         justify-content: space-evenly;
        //         align-items: center;
        //         width: clamp(500px, 80%, 600px);
        //         // z-index: 1;

        //         &::before {
        //             content: url('../../../media/spiral-line-arrow.svg');
        //             position: absolute;
        //             top: -20px;
        //             right: 60px;
        //             width: 0px;
        //             z-index: -1;
        //         }

        //         .event-address {
        //             display: flex;
        //             color: #000;
        //             font-weight: bold;
        //             font-size: 16px;

        //             img {
        //                 width: 20px;
        //                 margin-right: .5em;
        //             }
        //         }

        //         .event-date {
        //             display: flex;
        //             color: #000;
        //             font-weight: bold;
        //             font-size: 16px;
        //             width: 35%;
        //             line-height: 1;

        //             img {
        //                 width: 32px;
        //                 margin-right: .5em;
        //             }
        //         }

        //         .schedule-meeting-link {
        //             width: 170px;
        //             background-color: #011E3F;
        //             color: #fff;
        //             font-size: 16px;
        //             padding: .6em 1.5em;
        //             border-radius: 7px;
        //             line-height: 1.2;
        //         }
        //     }
        // }
        .full_size_event_banner_usa {
            min-height: 600px;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            // background-image: url('../../../media/bg-chicago-banner-img.webp');
            background-image: url('../../../media/bg-chicago-banner-img2.webp.webp');



            .container {
                .row {
                    align-items: center;

                    @media screen and (max-width:768px) {
                        flex-direction: column-reverse;
                    }
                }

                z-index: 1;

                .content {
                    position: relative;


                    h1 {
                        color: #fff;
                        font-size: 50px;
                        font-family: 'Syne', sans-serif;
                        font-weight: bold;
                        line-height: 55px;

                        @media screen and (min-width:280px) and (max-width:991px) {
                            font-size: 35px;
                        }

                        span {
                            color: #ffffff;
                            font-family: 'Poppins', sans-serif;
                        }

                        span.color_with_chicago {
                            color: #5AD8FF;
                        }

                    }

                    hr {
                        border: 2px solid #ffffff;
                        width: 61%;
                        opacity: 1;
                        margin-top: 25px;
                    }


                    p {
                        font-size: 21px;
                        width: 82%;
                        font-weight: 400;
                        color: #F9F9F9;
                        margin-top: 30px;
                        line-height: 32px;
                        font-family: 'Syne', sans-serif;

                        @media screen and (min-width:280px) and (max-width:991px) {
                            font-size: 14px;
                        }
                    }

                    a {
                        border: 1px solid #fff;
                        color: #fff;
                        border-radius: 25px;
                        padding: .5em 1.5em;
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        margin-top: 35px;

                        &:hover {

                            box-shadow: 0 4px 8px 0 #5ad8ff, 0 6px 15px 0 #152e36;
                            border: 1px solid #5ad8ff;
                            color: #5AD8FF;

                        }

                        svg {
                            margin-left: .5em;
                        }
                    }

                    h2 {
                        color: #fff;
                        font-weight: bolder;
                    }

                }
            }

        }

        .full_size_event_gitex_banner {
            // min-height: 600px;
            // display: flex;
            // align-items: center;
            // background-repeat: no-repeat;
            // background-size: cover;
            // background-position: center;
            // position: relative;
            // background-image: url('../../../media/seven.png');  
            min-height: 600px;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            background-image: url('../../../media/event-gitex-banner-2024-dubai.webp');



            .container {
                .row {
                    align-items: center;

                    @media screen and (max-width:768px) {
                        flex-direction: column-reverse;
                    }
                }

                z-index: 1;

                .content {
                    position: relative;


                    h1 {
                        color: #fff;
                        font-size: 50px;
                        font-family: 'Syne', sans-serif;
                        font-weight: bold;
                        line-height: 55px;

                        @media screen and (min-width:280px) and (max-width:991px) {
                            font-size: 35px;
                        }

                        span {
                            color: #ffffff;
                        }

                        span.color_with_chicago {
                            color: #5AD8FF;
                        }

                    }

                    hr {
                        border: 2px solid #ffffff;
                        width: 61%;
                        opacity: 1;
                        margin-top: 25px;
                    }


                    p {
                        font-size: 21px;
                        width: 82%;
                        font-weight: 400;
                        color: #F9F9F9;
                        margin-top: 30px;
                        line-height: 32px;
                        font-family: 'Syne', sans-serif;

                        @media screen and (min-width:280px) and (max-width:991px) {
                            font-size: 14px;
                        }
                    }

                    .gitex_more_buttons {
                        display: flex;
                        gap: 10px;

                    }

                    a {
                        border: 1px solid #fff;
                        color: #fff;
                        border-radius: 25px;
                        padding: .5em 1.5em;
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        margin-top: 35px;

                        &:hover {

                            box-shadow: 0 4px 8px 0 #5ad8ff, 0 6px 15px 0 #152e36;
                            border: 1px solid #5ad8ff;
                            color: #5AD8FF;

                        }

                        svg {
                            margin-left: .5em;
                        }
                    }

                    h2 {
                        color: #fff;
                        font-weight: bolder;
                    }

                }
            }
        }


        .full-size-event-banner-au {
            height: 600px;
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .event-banner-content {
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url('../../../media/event-bg-left.webp');
                background-repeat: no-repeat;
                background-size: cover;
                width: 50%;
            }

            .au-banner-part-one {
                width: 70%;
                margin-top: -8em;


                .event-banner-tagline {
                    color: #fff;
                    font-size: 18px;
                    font-weight: bolder;
                    letter-spacing: 1px;
                    position: relative;
                    margin-bottom: .6em;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 90px;
                        bottom: -4px;
                        height: 4px;
                        background-color: #FD226F;
                        border-radius: 25px;
                    }
                }

                p {
                    color: #fff;
                    margin-bottom: 2em;
                    font-size: 15px;
                }

                a {
                    background-color: #FD226F;
                    border: 1px solid #fff;
                    color: #fff;
                    border-radius: 25px;
                    padding: .5em 1.5em;
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    transition: all .2s;

                    &:hover {
                        font-weight: bold;
                        background-color: #fff;
                        border: 1px solid #FD226F;
                        color: #FD226F;
                        // transform: scale(.9);
                        transform: translateX(10px);
                    }

                    svg {
                        margin-left: .5em;
                    }
                }

                h2 {
                    color: #fff;
                    font-size: 3em;
                    font-weight: bolder;
                }
            }

            .australia,
            .au-banner-part-two,
            .du-banner-part-two,
            .singapore-banner-part-two,
            .america-banner-part-two,
            .first-banner-part-two,
            .second-banner-part-two,
            .third-banner-part-two,
            .forth-banner-part-two,
            .fifth-banner-part-two,
            .six-banner-part-two,
            .seven-banner-part-two,
            .eight-banner-part-two,
            .nine-banner-part-two,
            .ten-banner-part-two,
            .eleven-banner-part-two {
                background-image: url('../../../media/australia-event-bg.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position-x: center;
                width: 50%;
                height: inherit;
            }

            .du-banner-part-two {
                background-image: url('../../../media/dubai-event-bg.webp') !important;
            }

            .singapore-banner-part-two {
                background-image: url('../../../media/singapore-event.webp') !important;
            }

            .america-banner-part-two {
                background-image: url('../../../media/america-event.webp') !important;
            }

            .first-banner-part-two {
                background-image: url('../../../media/first.png') !important;
            }

            .australia {
                background-image: url('../../../media/australia.jpg') !important;
            }

            .second-banner-part-two {
                background-image: url('../../../media/second.png') !important;
            }

            .third-banner-part-two {
                background-image: url('../../../media/third.png') !important;
            }

            .forth-banner-part-two {
                background-image: url('../../../media/forth.png') !important;
            }

            .fifth-banner-part-two {
                background-image: url('../../../media/fifth.png') !important;
            }

            .six-banner-part-two {
                background-image: url('../../../media/six.png') !important;
            }

            .seven-banner-part-two {
                background-image: url('../../../media/seven.png') !important;
            }

            .eight-banner-part-two {
                background-image: url('../../../media/eight.jpg') !important;
            }

            .nine-banner-part-two {
                background-image: url('../../../media/nine.jpg') !important;
            }

            .ten-banner-part-two {
                background-image: url('../../../media/ten.jpg') !important;
            }

            .eleven-banner-part-two {
                background-image: url('../../../media/eleven.jpg') !important;
            }

            .schedule-meeting-bar {
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                display: flex;
                flex-wrap: wrap;
                border-radius: 16px;
                padding: .3em 1em;
                width: 35%;
                justify-content: space-evenly;
                align-items: center;
                width: clamp(500px, 80%, 600px);
                // z-index: 1;

                &::before {
                    content: url('../../../media/spiral-line-arrow.svg');
                    position: absolute;
                    top: -20px;
                    right: 60px;
                    width: 0px;
                    z-index: -1;
                }

                .event-address {
                    display: flex;
                    color: #000;
                    font-weight: bold;
                    font-size: 16px;

                    img {
                        width: 20px;
                        margin-right: .5em;
                    }
                }

                .event-date {
                    display: flex;
                    color: #000;
                    font-weight: bold;
                    font-size: 16px;
                    width: 35%;
                    line-height: 1;

                    img {
                        width: 32px;
                        margin-right: .5em;
                    }
                }

                .schedule-meeting-link {
                    width: 170px;
                    background-color: #011E3F;
                    color: #fff;
                    font-size: 16px;
                    padding: .6em 1.5em;
                    border-radius: 7px;
                    line-height: 1.2;
                }
            }
        }

        .full-size-event-banner-dl {
            height: 570px;
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .event-banner-content {
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url('../../../media/event-bg-left.webp');
                background-repeat: no-repeat;
                background-size: cover;
                width: 50%;
            }

            .dl-banner-part-one {
                width: 70%;
                margin-top: -8em;

                h2 {
                    color: #fff;
                    font-size: 20px;
                    font-weight: bolder;
                    letter-spacing: 1px;
                    position: relative;
                    margin-bottom: .6em;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 90px;
                        bottom: -8px;
                        height: 4px;
                        background-color: #FD226F;
                        border-radius: 25px;
                    }
                }

                .delhi-banner-details {
                    margin: 1em 0;

                    li {
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        line-height: 2;

                        svg {
                            font-size: 1.5em;
                        }
                    }
                }


                .event-banner-tagline {
                    color: #fff;
                    font-size: 18px;
                    font-weight: bolder;
                    letter-spacing: 1px;
                    position: relative;
                    margin-bottom: .6em;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 90px;
                        bottom: -4px;
                        height: 4px;
                        background-color: #FD226F;
                        border-radius: 25px;
                    }
                }

                p {
                    color: #fff;
                    margin-bottom: 2em;
                    font-size: 15px;
                }

                a {
                    background-color: #FD226F;
                    border: 1px solid #fff;
                    color: #fff;
                    border-radius: 25px;
                    padding: .5em 1.5em;
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    transition: all .2s;

                    &:hover {
                        font-weight: bold;
                        background-color: #fff;
                        border: 1px solid #FD226F;
                        color: #FD226F;
                        // transform: scale(.9);
                        transform: translateX(10px);
                    }

                    svg {
                        margin-left: .5em;
                    }
                }

                h2 {
                    color: #fff;
                    font-size: 3em;
                    font-weight: bolder;
                }
            }

            .dl-banner-part-two {
                background-image: url('../../../media/delhi-event.png');
                background-repeat: no-repeat;
                background-size: cover;
                background-position-x: center;
                width: 50%;
                height: inherit;
            }

            .schedule-meeting-bar {
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                display: flex;
                flex-wrap: wrap;
                border-radius: 16px;
                padding: .3em 1em;
                width: 35%;
                justify-content: space-evenly;
                align-items: center;
                width: clamp(500px, 80%, 600px);
                // z-index: 1;

                &::before {
                    content: url('../../../media/spiral-line-arrow.svg');
                    position: absolute;
                    top: -20px;
                    right: 60px;
                    width: 0px;
                    z-index: -1;
                }

                .event-address {
                    display: flex;
                    color: #000;
                    font-weight: bold;
                    font-size: 16px;

                    img {
                        width: 20px;
                        margin-right: .5em;
                    }
                }

                .event-date {
                    display: flex;
                    color: #000;
                    font-weight: bold;
                    font-size: 16px;
                    width: 35%;
                    line-height: 1;

                    img {
                        width: 32px;
                        margin-right: .5em;
                    }
                }

                .schedule-meeting-link {
                    width: 170px;
                    background-color: #011E3F;
                    color: #fff;
                    font-size: 16px;
                    padding: .6em 1.5em;
                    border-radius: 7px;
                    line-height: 1.2;
                }
            }
        }


        .hero_slide_section_img {
            width: 80%;
            margin: auto;
        }
    }

    .hero_slider {
        .inner_section {
            display: flex;
            height: 570px;
            align-items: center;

            .hero_slide_section_content {
                height: 100%;
                display: flex;
                align-items: center;

                .hero_content_div {
                    .h5_title {
                        color: #000;
                    }

                    .h2_title {
                        color: #000545;
                    }

                    p {
                        font-size: 1.1em;
                        color: #000;
                        margin-bottom: 2em;
                    }

                    .hero_cont_btn {
                        margin: 1em 0em;
                        background: transparent;
                        padding: 10px 45px;
                        border: 1px solid #6525ab;
                        position: relative;
                        overflow: hidden;
                        transition: all 0.2s;
                        z-index: 0;
                        border-radius: 25px;
                        color: #000;
                        display: inline-block;

                        &:hover {
                            color: #fff;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            bottom: 0%;
                            left: 0%;
                            width: 100%;
                            height: 100%;
                            background-image: linear-gradient(45deg, #6525ab, #914df4, #6525ab);
                            border-radius: 25px;
                            border: 1px solid #914df4;
                            transition: all 0.2s;
                            transform: scale(0);
                            z-index: -1;
                        }

                        &:hover::before {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .hero_content_div a {
        position: relative;
        top: -12px;
    }
}

@media screen and (max-width: 991px) {
    .hero-wrap .OwlCarousel-wrap .hero_slide_section_img {
        width: 100%;
    }

    .inner_section .row {
        align-items: center;
    }

    .hero-wrap .hero_slider .inner_section .hero_slide_section_content .hero_content_div .hero_cont_btn {
        padding: 10px 30px;
    }
}

@media screen and (max-width: 767px) {
    .hero-wrap .container {
        max-width: 100%;
    }

    .hero_slide_section_img {
        display: none;
    }
}

@media screen and (max-width: 575px) {
    .hero-wrap .hero_slider .inner_section .hero_slide_section_content .hero_content_div p {
        margin-bottom: 1em;
    }

    // .hero-wrap {
    //     padding: 1em 0em;
    // }
}

@media screen and (max-width:1025px) {
    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .uk-banner-part-two {
        display: none;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .au-banner-part-two,
    .du-banner-part-two {
        display: none;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .dl-banner-part-two {
        display: none;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .event-banner-content {
        width: 100%;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .event-banner-content {
        width: 100%;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .event-banner-content {
        width: 100%;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar::before {
        display: none;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar::before {
        display: none;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar::before {
        display: none;
    }
}

@media screen and (min-width:280px) and (max-width:991px) and (max-width:520px) {
    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar .event-address {
        width: 50%;
        justify-content: center;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar .event-address {
        width: 50%;
        justify-content: center;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar .event-address {
        width: 50%;
        justify-content: center;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar .event-date {
        width: 50%;
        justify-content: center;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar .event-date {
        width: 50%;
        justify-content: center;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar .event-date {
        width: 50%;
        justify-content: center;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar {
        height: 100px;
        width: 90%;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar {
        height: 100px;
        width: 90%;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar {
        height: 100px;
        width: 90%;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .uk-banner-part-one .event-banner-tagline {
        font-size: 13px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .au-banner-part-one .event-banner-tagline {
        font-size: 13px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .dl-banner-part-one .event-banner-tagline {
        font-size: 13px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .uk-banner-part-one h1 {
        font-size: 1.5em;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .au-banner-part-one h1 {
        font-size: 1.5em;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .dl-banner-part-one h1 {
        font-size: 1.5em;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .uk-banner-part-one p {
        font-size: 13px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .au-banner-part-one p {
        font-size: 13px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .dl-banner-part-one p {
        font-size: 13px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar .schedule-meeting-link {
        width: 100%;
        text-align: center;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar .schedule-meeting-link {
        width: 100%;
        text-align: center;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar .schedule-meeting-link {
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar .event-address {
        width: 100%;
        font-size: 14px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar .event-address {
        width: 100%;
        font-size: 14px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar .event-address {
        width: 100%;
        font-size: 14px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar .event-date {
        width: 100%;
        font-size: 14px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar .event-date {
        width: 100%;
        font-size: 14px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar .event-date {
        width: 100%;
        font-size: 14px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar {
        height: 130px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar {
        height: 130px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar {
        height: 130px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .schedule-meeting-bar .event-date img {
        width: 20px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .schedule-meeting-bar .event-date img {
        width: 20px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .schedule-meeting-bar .event-date img {
        width: 20px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-uk .uk-banner-part-one {
        margin-top: -100px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-au .au-banner-part-one {
        margin-top: -100px;
    }

    .hero-wrap .OwlCarousel-wrap .full-size-event-banner-dl .dl-banner-part-one {
        margin-top: -100px;
    }

}