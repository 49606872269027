.tabs-wrap {
    padding: 80px 0px 150px;
    background-color: #fafafb;
    // background: url("../../../images/background/contact_background.jpg");
    // background-repeat: no-repeat;

    .tabs-title {
        .h2_title {
            text-align: center;
            padding-bottom: 20px;
        }
    }

    // .nav-tabs {
    //     width: 50%;
    //     margin: 0px auto;
    //     border: none;
    //     margin-bottom: 0px !important;
    // }

    // :nth-child(2) .nav-link {
    //     left: -15px;
    // }

    // :nth-child(3) .nav-link {
    //     left: -35px;
    // }

    // :nth-child(4) .nav-link {
    //     left: -55px;
    // }

    // :nth-child(5) .nav-link {
    //     left: -75px;
    // }

    .nav-link {
        // border: 1px solid;
        // /* border-color: #dee2e6 #dee2e6 #fafafb; */
        border-radius: 0px;
        position: relative;
        border: none;
        background-color: #ffffff;
        color: #000;
    }

    .nav-link.active {
        // border-color: #fafafb #fafafb #cccccc;
        // box-shadow: 0px 5px 10px #cccccc;
        border: none;
        background-color: #ffffff;
        border-bottom: 2px solid #36bbc2;
    }

    .tabs-img {
        position: relative;
        padding: 2em;
    }

    img {
        height: 300px;
        object-fit: cover;
        // margin-top: 30px;
    }

    .tabs-text {
        // text-align: center;
        // max-width: 850px;
        margin: 20px auto 0px;

        button {
            background: transparent;
            padding: 10px 45px;
            border: 1px solid orange;
            position: relative;
            overflow: hidden;
            transition: all 0.2s;
            z-index: 0;
            border-radius: 25px;
            color: #000;
            margin-top: 30px;

            &:hover {
                color: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -10px;
                width: 10px;
                height: 10px;
                background-image: linear-gradient(45deg, #ff7731, #ffa52d, #ff742c);
                border-radius: 50%;
                border: 1px solid orange;
                transition: all 0.2s;
                z-index: -1;
            }

            &:hover::before {
                transform: scale(100);
            }
        }
    }
}