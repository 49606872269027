.about_section{
    padding: 50px 0px;
 
    .about_container{
        display: flex;
        align-items: center;
        height: 100%;

        .about_content{
            .h3_title{
                color: #36bbc2;
            }
        }
    }
    .about_image_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        img{
            width: 80%;
        }
    }
}
@media screen  and (max-width: 575px){
    .about_section .row{
        flex-direction: column-reverse;
    }
    .about_content{
        margin-bottom: 20px;
    }
    .about_section{
        padding: 20px 0px;
    }
}