.hireAddBanner_wrap {
    background-image: url("../../../images/hireDeveloperAdd/hireAddBanner.jpg");
    padding: 133px 0px 275px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .hireAddBanner_about {
        box-shadow: 0px 7.375px 7.375px rgba(12, 12, 12, 0.19);
        background: rgba(255, 255, 255, 0.96);
        padding: 50px 20px 50px 50px;
        position: relative;

        &::after {
            position: absolute;
            height: 100%;
            width: 9px;
            content: "";
            background-color: #F94D1C;
            left: 0px;
            top: 0px;
        }

        .hireAddBanner_link {
            background-color: #F94D1C;
            border-radius: 3.6875px;
            padding: 20px 40px;
            display: inline-block;
            color: #fff;
            margin-top: 20px;

            svg {
                vertical-align: middle;
            }

        }

        .h4_title {
            font-size: 16px;
            line-height: 22px;
            color: #000;
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            letter-spacing: 0;
            position: relative;
            display: inline-block;

            &::after {
                position: absolute;
                width: 45px;
                height: 3px;
                content: "";
                background-color: #F94D1C;
                right: -67px;
                top: 10px;
            }
        }

        .h2_title {
            letter-spacing: 0;
            font-weight: 700;
            font-size: 50px;
            line-height: 66px;
            color: #000;
            font-family: 'Poppins', sans-serif;
            padding: 8px 0px;

        }

        p {
            font-family: 'Poppins', sans-serif;
        }
    }
}

@media screen and (max-width: 1440px) {
    .hireAddBanner_wrap {
        padding: 100px 0px;
    }
}

@media screen and (max-width: 991px) {
    .hireAddBanner_wrap {
        padding: 50px 0px;
    }
}

@media screen and (max-width: 460px) {
    .hireAddBanner_wrap .hireAddBanner_about .h2_title {
        font-size: 35px;
        line-height: 40px;
    }
}

@media screen and (max-width: 360px) {
    .hireAddBanner_wrap .hireAddBanner_about .h4_title::after {
        width: 30px;
        height: 3px;
        right: -35px;
        top: 9px;
    }
}