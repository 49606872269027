.business_section {
    margin-top: 3rem;

    .head {
        margin-bottom: 2em;

        h2 {
            font-weight: 700;
            text-align: center;
            font-family: para;
            color: #36bbc2;
            font-size: 2.5rem;
        }
    }
}

.resource_section {
    margin-bottom: 2rem;

    .business_col {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .resource_left_content {
        padding: 4rem;
        margin: 1.5em 0em;
        text-align: center;
        background: rgba(255, 255, 255, 0.003);
        box-shadow: 0px 0px 23.1903px rgba(0, 5, 69, 0.08);
        border-radius: 18px;

        h2 {
            color: #36bbc2;
            font-family: heading;
            font-weight: 500;
        }

        p {
            padding: 3rem;

            @media (min-width:768px) and (max-width:991px) {
                padding: 1.2rem;
            }
        }

        @media screen and (max-width:991px) {
            padding: 5.1rem 0px;
        }

        @media (min-width:992px) and (max-width:1199px) {
            padding: 2rem;
        }

        @media (min-width:1200px) and (max-width:1399px) {
            padding: 3rem;
        }
    }

    .resource_right_content {
        .desc {
            background: rgba(255, 255, 255, 0.003);
            box-shadow: 0px 0px 23.1903px rgba(0, 5, 69, 0.08);
            border-radius: 18px;
            display: flex;
            align-items: center;
            gap: 30px;
            padding: 1rem 2rem;
            margin: 1.5em 0em;
            height: 95px;

            .image_box {
                width: 40px;
                height: 40px;

                img {
                    width: 18px;
                }
            }
        }
    }
}

.fixed_section {
    background: #00181C;
    padding: 3rem 0rem;
    background-image:linear-gradient(rgba(0,0, 0, 0.5),rgba(0,0, 0, 0.5),rgba(0,0, 0, 0.5)),url("../../../images/engagementModel/fixedBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    .business_col {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .fixed_left_content {
        .desc {
            background: #F9F9F9;
            box-shadow: 0px 0px 23.1903px rgba(0, 0, 0, 0.08);
            border-radius: 18px;
            display: flex;
            align-items: center;
            gap: 30px;
            padding: 1rem 2rem;
            margin: 1.5em 0em;
            height: 120px;
             @media screen and (max-width:1024px){
                height: 152px;
             }

            .image_box {
                width: 40px;
                height: 40px;

                img {
                    width: 18px;
                }
            }

            
        }
    }

    .fixed_right_content {
        background: #F9F9F9;
        box-shadow: 0px 0px 23.1903px rgba(0, 0, 0, 0.08);
        border-radius: 18px;
        padding: 6.9rem;
        margin: 1.5em 0em;
        text-align: center;

        h2 {
            color: #36bbc2;
            font-family: heading;
            font-weight: 500;
        }

        p {
            padding: 2.5rem;
           
        }

        @media screen and (max-width:767px){
            padding: 2.9rem 1rem;
        }
         @media (min-width:768px) and (max-width:991px){
            padding: 5.3rem;
         }
         @media (min-width:992px) and (max-width:1200px){
            padding: 6.2rem;
         }
    }
}

.hourly_section {
    margin: 3rem 0rem;

    .business_col {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .hourly_left_content {
        padding: 4rem;
        margin: 1.5em 0em;
        text-align: center;
        background: rgba(255, 255, 255, 0.003);
        box-shadow: 0px 0px 23.1903px rgba(0, 5, 69, 0.08);
        border-radius: 18px;

        h2 {
            color: #36bbc2;
            font-family: heading;
            font-weight: 500;
        }

        p {
            padding: 3rem;
        }
         
        @media screen and (max-width:424px){
            padding: 4rem 1rem;
        }
    }

    .hourly_right_content {

        .desc {
            background: rgba(255, 255, 255, 0.003);
            box-shadow: 0px 0px 23.1903px rgba(0, 5, 69, 0.08);
            border-radius: 18px;
            display: flex;
            align-items: center;
            gap: 30px;
            padding: 1rem 2rem;
            margin: 1.5em 0em;
            height: 103px;

            @media screen and (max-width:374px) {
                height: 140px;
            }
            @media (min-width:768px) and (max-width:991px){
                height: 150px;
             }
             @media (min-width:1024px) and (max-width:1199px){
                height: 132px;
             }

            .image_box {
                width: 40px;
                height: 40px;

                img {
                    width: 18px;
                }
            }
        }
    }
}