.previous_events_section {
    padding: 50px 0px;

    .previou_event_heading {
        h2 {
            text-align: center;
            letter-spacing: 0.246049px;
            color: #373668;
            font-weight: 700;
            font-size: 40px;
            line-height: 63px;
            font-family: pressfont;
        }
    }

    .previous_event_item {
        margin-top: 25px;
        background: #FFFFFF;
        box-shadow: 0px 15px 46px rgba(0, 0, 0, 0.1);
        border-radius: 7.87053px;

        .previous_event_img {
            width: 100%;
            height: 250px;

            img {
                border-top-left-radius: 7.87053px;
                border-top-right-radius: 7.87053px;
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }

        .previous_event_content {
            padding: 10px;
            height: 130px;

            .previous_event_title {
                font-size: 25px;
                font-weight: 700;
                letter-spacing: 0.145814px;
                color: #232325;
                font-family: pressfont;
            }

            .previous_event_desc {
                letter-spacing: 0.145814px;
                color: #232325;
                font-weight: 500;
                font-size: 11px;
                font-family: pressPara;
            }
        }

    }
}

@media screen and (max-width:767px){
    .previous_events_section .previous_event_item .previous_event_content {
        height: auto;
    }
}
@media (min-width:768px) and (max-width:1399px){
    .previous_events_section .previous_event_item .previous_event_content {
        height: 165px;
    }
}