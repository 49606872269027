@import './_banner';
@import './_allCases';

.img_side{
    width: 500px;
    display: block;
    margin: auto;
}

.head{
    h2{
        font-weight: 600;
    }
}
.banner_content{
    h2{
        font-weight: 600; 
    }
}

.carousel-indicators{
    display: none !important;
}