.hireAddWhyShould_wrap {
    background-image: url("../../../images/hireDeveloperAdd/why.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 35px 0px;

    .hireAddWhyShould_title {
        text-align: center;
        margin-bottom: 30px;

        .infograins_icon_img{
            margin-bottom: -10px;
            img{
                width: 189px;
            }
        }

        .h3_title {
            font-family: 'Poppins', sans-serif;
            color: #000;
            font-size: 37px;
            line-height: 40px;
            font-weight: 400;
            position: relative;
          
            &::after {
                position: absolute;
                width: 45px;
                height: 3px;
                content: "";
                background-color: #F94D1C;
                left: 48%;
                top: -19px;
            }

            span {
                font-family: 'Poppins', sans-serif;
                font-weight: 700;

            }
        }
    }

    .hireAddWhyShould_card_wrap {
        .hireAddWhyShould_card_article {
            text-align: center;
            margin-top: 40px;

            .h3_title {
                font-weight: 400;
                font-size: 28px;
                line-height: 36px;
                font-family: 'Poppins', sans-serif;
                text-transform: capitalize;
                color: #000;
                margin-bottom: 15px;
            }

            p {
                font-weight: 400;
                font-family: 'Poppins', sans-serif;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .hireAddWhyShould_wrap .hireAddWhyShould_title .h3_title {
        font-size: 31px;
        line-height: 35px;
    }

    .hireAddWhyShould_wrap .hireAddWhyShould_card_wrap .hireAddWhyShould_card_article {
        margin-top: 15px;
    }

    .hireAddWhyShould_wrap .hireAddWhyShould_card_wrap .hireAddWhyShould_card_article .h3_title {
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 10px;
    }

    .hireAddWhyShould_wrap .hireAddWhyShould_card_wrap .hireAddWhyShould_card_article p {
        font-size: 14px;
        line-height: 18px;
    }
}
@media screen and (max-width: 575px) {
    .hireAddWhyShould_card_wrap{
        margin-bottom: 20px;
    }   
    .hireAddWhyShould_wrap{
        padding: 20px 0px;
    }
}