.functionality_section {
    padding: 5em 0em;
    background-color: #fafafb;
    .head {
        text-align: center;
        margin-bottom: 2em;
    }
    .functionality_box {
        padding: 2em;
        border-radius: 5px;
        // box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
    }
}
@media screen and (max-width: 575px) {
    .functionality_section .functionality_box{
        margin-bottom: 20px;
    }
    .functionality_section {
        padding: 1em 0em;
    }
}