.web3-hero-wrap {
    padding: 50px 0px;
    // background-image: url("../../../images/background/web3/banner3.jpg");
    background-repeat: no-repeat;
    // height: 600px;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;

    .background_img {
        position: absolute;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &::after {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        width: 100%;
        height: 100%;
        // background: #000;
        background-image: linear-gradient(45deg, #000545c2, #000);
        // opacity: 0.5;
    }

    .row {
        align-items: center;
    }

    .w3-about-wrap {
        position: relative;
        z-index: 1;

        .h3_title,
        p {
            color: #fff;
        }

        .p_blockchain_dev {
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
            letter-spacing: 0.8px;
            margin-bottom: 0px;
            padding-top: 31px;
        }

        .h2_title {
            color: #36bbc2;
        }

        .h3_title {
            font-size: 4rem;
        }
    }

    .w3-from-wrap {
        background-color: #fff;
        position: relative;
        z-index: 1;
        padding: 20px;
        border-radius: 10px;

        .h3_title {
            text-align: center;
            padding-bottom: 20px;
        }

        .input_field {
            margin-bottom: 10px;
        }

        button {
            background: transparent;
            padding: 10px 45px;
            border: 1px solid #36bbc2;
            position: relative;
            overflow: hidden;
            transition: all 0.2s;
            z-index: 0;
            border-radius: 25px;
            color: #fff;
            width: 100%;
            margin-top: 20px;
            background-color: #36bbc2;

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -10px;
                width: 10px;
                height: 10px;
                background-image: linear-gradient(45deg, #36bbc2, #36bbc2, #36bbc2);
                border-radius: 50%;
                border: 1px solid #36bbc2;
                transition: all 0.2s;
                z-index: -1;
            }

            &:hover::before {
                transform: scale(100);
            }
        }

        .btn-primary:hover {
            border-color: #36bbc2;
            color: #fff;
        }
    }

    .mobile_div {
        display: flex;

        .form-select {
            background-color: transparent;
            height: 40px;
            width: 140px;
            border: none;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .web3-hero-wrap .container {
        max-width: 100%;
    }

    .web3-hero-wrap .w3-about-wrap .h3_title {
        font-size: 2em !important;
    }
}

@media (max-width: 575px) {
    .w3-about-wrap {
        margin-bottom: 20px;
    }

    .achievements-title {
        margin-bottom: 20px;
    }

    .w3-service-about {
        padding-top: 20px;
    }

    .web3-hero-wrap {
        padding: 20px 0px;
    }
}