// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

// @font-face {
//     font-family: heading;
//     src: url("../fonts/BebasNeue-Regular.ttf");
// }

// @font-face {
//     font-family: paragraph;
//     src: url('../fonts/Lexend-VariableFont_wght.ttf');
// }

// @font-face {
//     font-family: para;
//     src: url("../fonts/Quicksand-VariableFont_wght.ttf");

// }

// @font-face {
//     font-family: pressfont;
//     src: url("../fonts/Inter-VariableFont_slnt\,wght.ttf");
// }

// @font-face {
//     font-family: pressPara;
//     src: url("../fonts/Poppins-Regular.ttf");
// }

// @font-face {
//     font-family: monaSansRegular;
//     src: url("../fonts/Mona-Sans-Regular.ttf");
// }
// @font-face {
//     font-family: monaSansRegular;
//     src: url("../fonts/Calibri.ttf");
// }

// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@import url("../style/common/header.scss");
@import url("../style/pages/landing_page/index.scss");
@import url("./pages/blockchain/blockchain.scss");
@import url("../style/pages/blog/blogsection.scss");
@import url("../style/pages/blog/single_blog.scss");
@import url("./pages/not_found.scss");
@import url("./pages/aboutUs/aboutUs.scss");
@import url("./pages/contactUs/contactUs.scss");
@import url("./pages/ourTeam/ourTeam.scss");
@import url("./pages//games/unityGame/unityGame.scss");
@import url("./pages/nft/index.scss");

@import url("./pages/product/productWeb/productWeb.scss");
@import url("./pages/resources/resources.scss");

@import url("./pages/product/singleProduct/product_page.scss");
@import url("./pages/privacy/privacy.scss");
@import url("./pages/term_condition/term_condition.scss");
@import url("./pages/career/career.scss");
@import url("./pages/particle.scss");
@import url("./pages/siteMap.scss");
@import './pages/hire/__main';
@import './pages/caseStudies/_main';
@import './pages/caseStudies/_caseStudiesDetails';
@import url("./pages/engagementModels/main_engagement.scss");
@import "./pages/hiredeveloperadd/hireDeveloperAdd";
@import url("./pages/ventureCdn/main.scss");

@import url("./pages/pressRelease/PressMain.scss");

@import "./common/successModel";
@import "./pages/articles/_articles";

@import "./pages/hyderabad/_main";

@import "./new-pages/landing/_landingIndex";
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');

@import "./agriculture/_agriculture";
@import "./diabetic/_diabetic";
@import "./ipl-prediction/_ipl-prediction";
@import "./leaf_prediction/_leaf_prediction";

* {
    // font-family: paragraph;
    // font-family: 'Quicksand', sans-serif;
    // font-family: para;
    font-family: 'Syne', sans-serif;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: #F68633;
}

::selection {
    color: #fff;
    background: #F68633;
}

html {
    scroll-behavior: smooth;
}

body {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure,
ul,
li,
ol,
a,
p {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    list-style: none;
}

hr {
    margin: 1px 0px;
    color: #b7b7b7;
}

a {
    color: #000545;
    transition: all 0.5s ease;
}

a:hover {
    color: #36bbc2;
    text-decoration: none;
}

img {
    max-width: 100%;
    width: 100%;
}

.h1_title {
    font-size: 4rem;
    color: #000545;
    font-family: 'Syne', sans-serif;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.h2_title {
    font-family: 'Syne', sans-serif;
    font-size: 3rem;
    color: #000545;
    letter-spacing: 1px;
    margin-bottom: 0px;
}
.h2_title_technology {
    font-family: 'Syne', sans-serif;
    font-size: 2.6rem;
    color: #000545;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.h2_title_block_chain {
    font-family: 'Syne', sans-serif;
    font-size: 3rem;
    color: #000545;
    letter-spacing: 1px;
    margin-bottom: 25px;
}
.ai_h2_title {
    font-family: 'Syne', sans-serif;
    font-size: 3rem;
    color: #000545;
    letter-spacing: 1px;
    margin-bottom: 0px;
}
.h3_title {
    font-family: 'Syne', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #000545;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.h4_title {
    font-family: 'Syne', sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    color: #000545;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.h5_title {
    font-family: 'Syne', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000545;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.h6_title {
    font-family: 'Syne', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000545;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

p {
    // font-family: paragraph;
    font-weight: 400;
    font-size: 16px;
    // color: #000;
    color: #47576a;
    letter-spacing: 0.7px;
    margin-bottom: 0px;
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.btn:focus {
    box-shadow: none !important;
}

.input_field {
    border: none !important;
    background: transparent !important;
    // border-bottom: 2px solid orange !important;
    border-bottom: 1px solid #47576a !important;
    border-radius: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    // margin-bottom: 50px !important;
    &:focus {
        box-shadow: none !important;
    }
}

.warning {
    display: flex;
    justify-content: center;

    b {
        display: flex;
        align-items: center;

        svg {
            color: #ffb600;
            font-size: 2em;
            margin-right: 0.5em;
        }
    }
}

.modal-backdrop.show {
    opacity: 1;
    background: #ffffff4a;
    backdrop-filter: blur(4px);
}

// textarea {
//     resize: none;
// }

.spin_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.partner_image_EMPTY {
    width: 80px;
    height: 80px;
    background-color: #ededed;
    border-radius: 50%;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &::before {
        filter: blur(5px);
        content: "";
        position: absolute;
        top: 28%;
        left: 0%;
        width: 200%;
        height: 30px;
        background-color: #f4f4f4;
        box-shadow: 0px 0px 20px #ececec;
        transform: rotate(45deg);
        transition: all 0.5s;
        animation: loadData 1s linear infinite;
        z-index: 1;

        @keyframes loadData {
            0% {
                left: -100%;
            }

            100% {
                left: 120%;
            }
        }
    }
}

.EMPTY_loader_content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .EMPTY_loader_subhead {
        border-radius: 4px;
        width: 100px;
        height: 20px;
        background-color: #ececec;
        margin-bottom: 1em;
    }

    .EMPTY_loader_head {
        border-radius: 4px;
        width: 300px;
        height: 30px;
        background-color: #ececec;
        margin-bottom: 1em;
        margin-right: 7em;
    }

    .para_EMPTY {
        border-radius: 4px;
        margin-bottom: 1em;
        width: 500px;
        height: 20px;
        background-color: #ececec;
    }

    .EMPTY_btn {
        background-color: #ececec;
        width: 100px;
        height: 40px;
        border-radius: 4px;
        border: none;
    }
}

.EMPTY_loader_img {
    border-radius: 4px;
    width: 600px;
    height: 400px;
    background-color: #ececec;
    position: relative;
    overflow: hidden;

    &::before {
        filter: blur(5px);
        content: "";
        position: absolute;
        top: 28%;
        left: 0%;
        width: 200%;
        height: 80px;
        background-color: #f4f4f4;
        box-shadow: 0px 0px 20px #ececec;
        transform: rotate(45deg);
        transition: all 0.5s;
        animation: loadData 1s linear infinite;
        z-index: 1;

        @keyframes loadData {
            0% {
                left: -100%;
            }

            100% {
                left: 120%;
            }
        }
    }
}

// ============================== rectangle_shape ==============================
.rectangle_shape {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholder;
    animation-timing-function: linear;
    background: #ededed;
    background: linear-gradient(45deg, #ededed 8%, #f4f4f4 18%, #ededed 33%);
    background-size: 1200px 100px;
    min-height: 100px;
    width: 150px;
    margin: 5px 0 5px 0;
    border-radius: 3px;
    margin: 5px auto;
}

.active {
    color: orangered;
}

// ================== scrollbar =====================
::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    width: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #36bbc2;
    border-radius: 25px;
}

button {

    .item {
        overflow: hidden;
        height: 30px;

        .sl-spinner2 {
            height: 30px;
        }
    }
}

.banner-from-wrap {
    background-color: #fff;
    position: relative;
    z-index: 1;
    padding: 20px;
    border-radius: 10px;

    .h3_title {
        text-align: center;
        padding-bottom: 20px;
    }

    .input_field {
        margin-bottom: 10px;
    }

    button {
        margin-top: 20px;
        background: transparent;
        padding: 10px 45px;
        border: 1px solid #36bbc2;
        position: relative;
        overflow: hidden;
        transition: all 0.2s;
        z-index: 0;
        border-radius: 25px;
        color: #000;
        width: 100%;
        // width: 100%;

        &:hover {
            color: #fff;
            border-color: #36bbc2;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: 10px;
            height: 10px;
            background-image: linear-gradient(45deg, #79dee3, #36bbc2, #79dee3);
            border-radius: 50%;
            border: 1px solid #36bbc2;
            transition: all 0.2s;
            z-index: -1;
        }

        &:hover::before {
            transform: scale(160);
        }

    }

    .btn:hover {
        background-color: #36bbc2;
    }

    .btn-primary:hover {
        border-color: #36bbc2;
        color: #fff;
    }
}

.mobile_div {
    display: flex;

    .form-select {
        background-color: transparent;
        height: 40px;
        width: 140px;
        border: none;

        &:focus {
            box-shadow: none;
        }
    }
}

.btn:disabled {
    background-color: #36bbc2;
    border: 1px solid #36bbc2;
}

@supports (animation: grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
    .congrats_popup {
        text-align: center;
        position: absolute;
        z-index: 9999999999;
        top: 0px;
        background: rgba(120, 119, 119, 0.284);
        height: 100vh;
        width: 100%;
        margin: 0px auto;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .svg-container {
        background: #fff;
        padding: 20px;
        border-radius: 5px;

        p {
            font-weight: 600;
            margin-top: 10px;
        }
    }

    .tick {
        stroke-opacity: 0;
        stroke-dasharray: 29px;
        stroke-dashoffset: 29px;
        animation: draw .5s cubic-bezier(.25, .25, .25, 1) forwards;
        animation-delay: .6s
    }

    .success {
        fill-opacity: 0;
        stroke: #219a00;
        stroke-width: 16px;
        transform-origin: center;
        transform: scale(0);
        animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;
    }
}

@keyframes grow {
    60% {
        transform: scale(.8);
        stroke-width: 4px;
        fill-opacity: 0;
    }

    100% {
        transform: scale(.9);
        stroke-width: 8px;
        fill-opacity: 1;
        fill: #219a00;
    }
}

@keyframes draw {

    0%,
    100% {
        stroke-opacity: 1;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes placeholder {
    0% {
        background-position: -600px 0
    }

    100% {
        background-position: 600px 0
    }
}

// ============================== rectangle_shape ==============================
@media screen and (max-width: 1199px) {
    .banner-from-wrap {
        .input_field::placeholder {
            font-size: 10px;
        }
    }

}

@media screen and (max-width: 911px) {
    .banner-from-wrap {
        .input_field::placeholder {
            font-size: 14px;
        }
    }
}


@media screen and (max-width: 767px) {
    // .h2_title {
    //     font-size: 2rem;
    // }

    // .h3_title {
    //     font-size: 16px;
    // }

    .container {
        max-width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .banner-from-wrap {
        .input_field::placeholder {
            font-size: 10px;
        }
    }
}

@media screen and (max-width: 575px) {
    p {
        font-size: 14px;
    }

    .h2_title {
        font-size: 2em;
    }

    .h2_title_block_chain {
        font-size: 2em;
    }
    .h2_title_technology{
        font-size: 1.6em;
    }

    .h3_title {
        font-size: 1.5em;
    }

    .h4_title {
        font-size: 1em;
    }

    .banner-from-wrap {
        margin-top: 20px;
    }

    .banner-from-wrap {
        .input_field::placeholder {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 360px) {
    .banner-from-wrap {
        .input_field::placeholder {
            font-size: 10px;
        }
    }
}

@media screen and (max-width: 320px) {
    .banner-from-wrap {
        .input_field::placeholder {
            font-size: 8px;
        }
    }
}

.search_email {
    &::placeholder {
        color: #333;
    }
}

.cursor-web {
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0;
    left: 0;
    z-index: 4;
    border-radius: 50%;

    @media screen and (max-width:991px) {
        display: none;
    }
}

// animation: circleShadow 1s linear infinite;

// @keyframes circleShadow {
//     0% {
//         box-shadow: 0px 0px 5px 5px #F68633;
//     }

//     100% {
//         box-shadow: 0px 0px 15px 15px #F68633;
//         opacity: 0;
//     }
// }
// =========================================
.thank-you-page {
    padding: 4em 0;

    .wrapper-1 {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .wrapper-2 {
        padding: 30px;
        text-align: center;
    }

    h1 {
        font-family: 'Kaushan Script', cursive;
        font-size: 4em;
        letter-spacing: 3px;
        color: #f68633;
        margin: 0;
        margin-bottom: 20px;
    }

    .wrapper-2 p {
        margin: 0;
        font-size: 1.3em;
        color: #aaa;
        font-family: 'Source Sans Pro', sans-serif;
        letter-spacing: 1px;
    }

    .go-home {
        color: #fff;
        background: #f68633;
        border: none;
        padding: 10px 50px;
        margin: 30px auto;
        border-radius: 30px;
        text-transform: capitalize;
        box-shadow: 0 10px 16px 1px rgb(251 206 174);
        width: fit-content;
    }

    .footer-like {
        margin-top: auto;
        background: #D7E6FE;
        padding: 6px;
        text-align: center;
    }

    .footer-like p {
        margin: 0;
        padding: 4px;
        color: #f68633;
        font-family: 'Source Sans Pro', sans-serif;
        letter-spacing: 1px;
    }

    .footer-like p a {
        text-decoration: none;
        color: #f68633;
        font-weight: 600;
    }

    @media (min-width:360px) {
        h1 {
            font-size: 4.5em;
        }

        .go-home {
            margin-bottom: 20px;
        }
    }

    @media (min-width:600px) {
        .content {
            max-width: 1000px;
            margin: 0 auto;
        }

        .wrapper-1 {
            height: initial;
            max-width: 620px;
            margin: 0 auto;
            margin-top: 50px;
            box-shadow: 4px 8px 40px 8px rgb(251 206 174);
        }

    }
}

@media screen and (max-width:600px) {
    .schedule_content .toUppercase {
        flex-basis: 100% !important;
    }
}

.know-our-divs {
    min-height: 117px;

    p {
        color: #ffffffad;
    }
}

.booth_details {
    color: #000;
    margin-bottom: 2em;

    p {
        margin: 0px !important;
        font-weight: bold;
    }
}

// ======================================================
/* ======================================================= */
.our_delegates {
    padding: 4em 0;

    .__head {
        margin-bottom: 4em;

        h2 {
            text-transform: uppercase;
            font-size: 4.5em;
            font-weight: bold;
            color: #343f52;

            @media screen and (max-width:610px) {
                font-size: 2.5em;
            }
        }
    }
}

.meeting_section {
    padding: 4em 0;

    .__head {
        h2 {
            text-transform: uppercase;
            font-size: 4.5em;
            font-weight: bold;
            color: #959ca9;
            opacity: .2;

            @media screen and (max-width:610px) {
                font-size: 2.5em;
                margin-bottom: 1em;
            }
        }
    }
}

.delegate_wrapper {
    .image_wrapper {
        position: relative;

        a {
            position: absolute;
            bottom: 5px;
            right: 25px;
            background-color: rgb(255 255 255 / 69%);
            padding: 0.2em 0.5em;
            border-radius: 5px;

            &:hover {
                color: unset;
                background-color: rgb(255 255 255 / 90%);
            }

            .arrow {
                transform: rotate(45deg);
            }

            svg {
                margin: 0 2px;
            }
        }
    }

    .profile_details {
        margin-top: 1em;
    }
}


// ========================= pop_up_schedule_form =====================================
.event_popUp {
    .modal-dialog {
        max-width: 1200px;
    }
}

.pop_up_schedule_form {
    form {
        label {
            color: #000;
        }

        input,
        textarea {
            background-color: #ffffff;
            border: 2px solid #dfdfdf;
            border-radius: 10px;
            padding: 10px 15px 10px 15px;
            width: 100%;
            color: #2c2b2b;
        }

        .react-tel-input {
            background: #fff;

            .know-schedule-inputs {
                background-color: #ffffff !important;
                color: #2c2b2b !important;
                border: 2px solid #dfdfdf !important;
            }
        }

        ::placeholder {
            color: #9c9c9c;
        }

        .react-tel-input .country-list,
        .react-tel-input .country-list .search {
            background-color: #fff !important;
        }

        .react-tel-input {
            color: #2c2b2b;
        }

        .react-tel-input .country-list {
            overflow-x: hidden;
        }

        .konw-form-div-wi {
            width: 80%;
        }

        @media screen and (max-width:767px) {

            .konw-form-div-wi,
            .know-schedule-btn {
                width: 100%;
            }
        }
    }
}

// ========================= pop_up_schedule_form =====================================
// ========================== event page form top =========================================
.hero_section_event_page {
    padding: 4em 0;

    @media screen and (max-width:520px) {
        padding: 0;
    }

    .heading_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        @media screen and (max-width:991px) {
            margin-bottom: 4em;
        }

        .heading_area {
            h1 {
                color: #fff;
                font-size: 3em;
                font-weight: bold;
                border-bottom: 1px solid #fff;
                padding-bottom: .5em;
                margin-bottom: .2em;

                @media screen and (max-width:1120px) {
                    font-size: 2em;
                }

                @media screen and (max-width:520px) {
                    font-size: 1.5em;
                }
            }

            p {
                color: #ececec;
                font-size: 20px;
            }
        }

        .heading_area_event_gitex {
            h1 {
                color: #fff;
                font-size: 2.5em;
                font-weight: bold;
                border-bottom: 1px solid #fff;
                padding-bottom: .5em;
                margin-bottom: .2em;

                @media screen and (max-width:1120px) {
                    font-size: 2em;
                }

                @media screen and (max-width:520px) {
                    font-size: 1.5em;
                }
            }

            h2 {
                color: #fff;
                font-size: 1.5em;
                font-weight: bold;
                border-bottom: 1px solid #fff;
                padding-bottom: .5em;
                margin-bottom: .2em;

                @media screen and (max-width:1120px) {
                    font-size: 1em;
                }

                @media screen and (max-width:520px) {
                    font-size: 0.8em;
                }
            }

            span {
                color: #fff;
                font-size: 37px;
                font-weight: bold;
                padding-bottom: 0 .5em;
                margin-bottom: 2rem;
                line-height: 51px;


                @media screen and (max-width:1120px) {
                    font-size: 22px;
                }

                @media screen and (max-width:520px) {
                    font-size: 18px;
                }
            }

            p {
                color: #ececec;
                font-size: 20px;
            }
        }
    }

    .event_page_lead_form {
        background-color: #fff;
        height: 100%;
        width: 100%;
        margin: auto;
        display: grid;
        place-items: center;
        border-radius: 15px;


        form {
            width: 100%;
            padding: 3em 1em 1em 1em;
            border-radius: 15px;
            box-shadow: 0px 0px 25px #fff;

            label {
                color: #000;
            }

            input,
            textarea {
                background-color: #ffffff;
                border: 2px solid #dfdfdf;
                border-radius: 10px;
                padding: 10px 15px 10px 15px;
                width: 100%;
                color: #2c2b2b;
            }

            .react-tel-input {
                background: #fff;

                .know-schedule-inputs {
                    background-color: #ffffff !important;
                    color: #2c2b2b !important;
                    border: 2px solid #dfdfdf !important;
                    padding-left: 3em;
                }
            }

            ::placeholder {
                color: #9c9c9c;
            }

            .react-tel-input .country-list,
            .react-tel-input .country-list .search {
                background-color: #fff !important;
            }

            .react-tel-input {
                color: #2c2b2b;
            }

            .react-tel-input .country-list {
                overflow-x: hidden;
            }

            .konw-form-div-wi {
                width: 100%;
                padding: 0;
            }

            button {
                width: 100%;
                margin: 0 1.5em;
            }

            @media screen and (max-width:767px) {

                .konw-form-div-wi,
                .know-schedule-btn {
                    width: 100%;
                }
            }
        }
    }
}

.konw-main-bg {
    .contact_number_css {
        display: flex;
        justify-content: center;
        // border-bottom: 1px solid #434448;

        a {
            color: #fff;
            padding: 0.5em 1em 0em 1em;
            font-weight: bold;
        }

        .call_icon img {
            height: auto;
            width: 56px;
        }
    }
}

.social_media_links_in_know_footer {
    display: flex;
    justify-content: end;
    align-items: center;

    a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        svg {
            font-size: 1.1em;
        }

        &:hover {
            color: #F48533;
            transform: translateY(-10px) scale(1.2);
        }
    }
}