.section_one {
    background-image: linear-gradient(90deg, #EDEBEA 0%, #F9F9F7 100%);
    padding: 4em 0;
    position: relative;

    &::before {
        background-image: url('../../../../assets/media/new-landing/white-grains.png');
        content: '';
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
    }

    .container{
        z-index: 1;
        position: relative;
    }

    .row {
        padding: 2em 0;
    }
    empowering
    .row:nth-child(2){
        @media screen and (max-width:768px) {
            flex-direction: column-reverse;
        }
    }

    .keyPoint_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid gray;
        border-radius: 15px;
        padding: 2em 1em;
        margin: 1em 0;

        .value {
            font-weight: bold;
            font-size: 1.5em;
        }

        .text {
            text-align: center;
        }

    }

    .content {
        h2 {
            color: #14181E;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1px;
            @media screen and (min-width:280px) and (max-width:991px) {
                font-size: 25px;
            }
        }

        .btn_group {
            display: flex;
            margin-top: 2em;
            flex-wrap: wrap;

            button:nth-child(1) {
                color: #fff;
                font-weight: 600;
                font-size: 1.1em;
                border-radius: 46px;
                background: #1F2122;
                padding: .2em 1em;
                border: none;
                display: flex;
                align-items: center;
                margin-right: 1em;

                svg {
                    margin-right: 1em;
                    path{
                        fill:#fff;
                    }
                }

                @media screen and (min-width:280px) and (max-width:991px) {
                    height: 50px;
                }
            }

            a {
                font-weight: 600;
                border-radius: 46px;
                background: rgba(0, 0, 0, 0.08);
                padding: .2em 1em .2em 0em;
                border: none;
                display: flex;
                align-items: center;
                margin-right: 1em;
                font-size: 1.1em;

                &:hover{
                    color: #14181E;
                }

                &>div {
                    background-color: #1F2122;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1em;
                }

                svg {
                    path{
                        fill:#fff;
                    }
                }
            }

            button{
                @media screen and (min-width:280px) and (max-width:991px) {
                    margin: .5em 0;
                }
            }
        }
    }

    .section_one_img{
        filter: grayscale(1);
        
        &:hover{
            filter: grayscale(0);

        }
    }
}