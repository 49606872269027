.pricingPlan_section{
    padding: 4em 0em;
    .head{
        text-align: center;
        margin-bottom: 2em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h2{
            font-weight: bold;
            margin-bottom: 1em;
            position: relative;
            z-index: 0;
            background: #fff;
            width: fit-content;

            &::before {
                content: 'Plan';
                position: absolute;
                top: -120%;
                z-index: -1;
                left: 0%;
                width: fit-content;
                height: 100%;
                font-size: 2.1em;
                font-weight: 900;
                // -webkit-text-stroke: 4px;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: lightgrey;
                color: #fff;
                font-family:Verdana, Geneva, Tahoma, sans-serif;
            }
        }
    }

    .pricing_div{
        border: 1px solid lightgrey;
        padding: 1.5em;

        .pricing_values{
            margin-top: 2em;
            .price{
                text-align: center;
                font-size: 3em;
                font-weight: 900;

                sub{
                    font-size: 16px;
                }
            }
            .tag_line{
                font-size: 14px;
                margin: .5em 0em;
            }
        }


        .pricing_values{
            button{
                width: 100%;
                background-color: #627afe;
                padding: 0.5em 1.5em;
                color: #fff;
                border: none;
                transition: all 0.2s;
            }
        }
    }
}