html {
  scroll-behavior: smooth;
}

.light-color {
  color: #f3f3f3;
}

.main-color {
  color: #F48533;
}

/* Main section Css Start  */
.top-img {
  width: 100%;
}

@media screen and (max-width:466px) {
  .top-img {
    width: 100%;
  }
}

.top-div {
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
}

.top-div2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  border-left: 1px solid rgba(255, 255, 255, 0.20);
}

.konw-main-bg {
  background-image: url('../../../assets/images/konwMore/know-main-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.know-main-text {
  margin-top: 15vw;
}

.toUppercase {
  text-transform: uppercase;
}

.know-main-text h1 {
  font-weight: bold;
}

.know-main-text span {
  color: #F48533;
}

.Kown-main-p {
  background: rgb(82, 82, 82);
  background: rgba(73, 73, 73, 0.5);
}

.Kown-main-p p {
  padding: 2px 10px 2px 10px;
  width: 100%;
  color: white;
  background: rgb(82, 82, 82);
  background: rgba(73, 73, 73, 0.5);
}

.top-images-div {
  margin-bottom: 3vw;
}

.konw-shedu-div {
  margin-bottom: 10vw;
}

.know-schedu-btn {
  background-color: #F48533;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 5px 20px 5px 20px;

  &:hover{
    color: #fff;
  }
}

.know-event-trip {
  background: linear-gradient(90deg, #020710 0.21%, #141921 100%);
}

.know-About-us {
  background: linear-gradient(90deg, #141921 0.21%, #020710 100%);
}

.know-few-reason {
  background-image: url('../../../assets/images/konwMore/BG\ img.png');
  background-color: #020710;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.know-few-reason-row {
  margin-bottom: 5vw;
}

.know-few-reason h1 {
  font-weight: bold;
  color: rgb(255, 255, 255);
}

#clt-and-gfr-img-id{
  display: flex;
  align-items: center;
}

#tb-logo-id{
  padding-right: 3rem;
  /* padding-top: 1.7rem; */
}

#footer-address-id{
    background-color: white;
    padding-top: 4px;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 17px;
    box-shadow: 0px 1px 13px 2px white;
}

#bottom-logos-id{
  justify-content: center;
}

/* #clutch-widget-id{
  padding-top: 2.3rem;
} */

@media screen and (max-width:466px) {
  .know-few-reason h1 {
    padding: 10px !important;
  }
}

.konw-few-cards {
  background: linear-gradient(135deg, #2A3039 0%, rgba(42, 48, 57, 0.00) 100%);
  border-radius: 15px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width:466px) {
  .row .konw-few-cards {
    padding: 10px !important;
  }
}

.know-few-circle {
  /* background-color: #F48533;
  width: 50px;
  height: 50px;
  border-radius: 25px; */
    background-color: #F48533;
    width: 55px;
    height: 55px;
    border-radius: 25px;
    margin-left: -3.5rem;
    margin-top: -3.2rem;
}

.know-fev-span {
  flex-shrink: 0;
  margin-left: 35px;
  margin-top: -22px;
  color: rgb(238, 0, 0);
}

.know-few-span-number{
     margin-left: -2.6rem !important;
    margin-top: -3rem !important;
}

.new-event-expertise{
  padding: 31px 46px 31px 46px !important;
}

.new-dev-expertise{
  width: 70% !important;
  margin: auto !important;
}

/* Our Area Start  */

.know-our-h1 {
  font-weight: bold;
  color: white;
}

.know-our-areas {
  background-image: url('../../../assets/images/konwMore/bg\ logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-color: #070C15;
  margin-top: auto;
}

.konw-our-span {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(135deg, #2A3039 0%, rgba(42, 48, 57, 0.00) 100%);
  color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 12px;
  width: 85%;
}

/* Our Area END  */

/* schedule Start  */
.konw-schedule-bg {
  background-color: #020710;
}

.konw-schedule-span {
  font-weight: bold;
}

.schedule-form {
  max-width: 700px;
}

.know-schedule-inputs {
  background-color: #2c2b2b;
  border: 2px solid gray;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  width: 100%;
  color: white;
}

.know-schedule-inputs[type=text]:focus {
  border: 2px solid #F48533;
  outline: 0;
  box-shadow: none;

}

input::placeholder {
  color: rgb(255, 255, 255);
}

.know-schedule-btn {
  background: #F94D1C;
  color: white;
  width: 30%;
  /* height: 5%; */
  padding: 10px 20px 10px 20px;
  border: none;
  border-radius: 20px;

}

.konw-form-div-wi {
  width: 60%;
}

.award-logo-div{
  padding-bottom: 2rem;
  padding-top: 10px;
  padding-left: 3rem;
}

.award-logo-div img{
  width: 11rem;
}

.our-expertise{
  cursor: pointer;
  display: inline-block; 
}
.our-expertise:hover{
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.set-apart-bold{
  margin-left: 5px;
  margin-top: 1.2rem;
  padding-bottom: 1rem;
  line-height: 25px;
}

.konw-few-cards-new{
  overflow: hidden !important;
}

.set-apart-bold b{
  font-size: 1.2rem;
  font-weight: bolder;
}

#konw-few-cards-id{
  overflow: hidden;
}

@media screen and (max-width:446px) {

  .form__event .__wrapper {
    width: 100%;
  }
  .form__event .__wrapper .konw-form-div-wi{
    width: 100%;
  }
  
  .know-schedule-btn{
    width: 100%;
  }
}

@media screen and (max-width: 990px) and (min-width: 350px) {
  #clt-and-gfr-img-id {
    display: grid;
    place-items: center;
    gap: 3rem;
  }

  #clt-and-gfr-img-id {
    display: grid;
    place-items: center;
    gap: 3rem;
  }

  #tb-logo-id {
    padding-right: 0rem;
    padding-top: 1.7rem;
    padding-bottom: 1rem;
  }
  #clutch-widget-id{
    padding-left: 6rem;
  }
  .good-firm-new{
    padding-left: 2rem;
  }
  .award-logo-div{
    padding-bottom: 2rem;
    padding-top: 0px;
    padding-left: 0rem;
  }
  .new-padding-responsive{
    padding-top: 2rem;
  }
  #new-responsive-container{
    padding-top: 5rem !important;
  }
}

/* schedule END  */

/* Create Solution Start  */

.know-create-solu {
  background-color: #020710;
}

.know-create-solu-heading {
  color: white;
  font-weight: bold;
  text-align: center;
}

.konw-solution-lisk {
  color: white;
}

.konw-solution-list {
  color: white;
  /* font-size: 12px; */
}

.konw-solution-list li {
  cursor: pointer;
}

.active2 {
  background-color: #2A3039;
  /* padding: 2px; */
  /* border:0px 0px 2px 0px solid #F48533; */
  border-bottom: 2px solid #F48533;
  width: 200px;
  align-items: center;
  font-size: 18px;
  margin-right: 5px;
  color: white;
}

.bg-solu-content {
  color: white;
  background-image: url('../../../assets/images/konwMore/solution-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.solu-content-text {

  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 100%;
}

/* Create Solution END  */


/* Footer Start  */
.konw-footer {
  background-color: #020710;
}

/* Footer END  */



.btn-up-centent {
  position: fixed;
  bottom: 50px;
  right: 20px;
  font-size: 50px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.btn-up-centent:hover {
  color: #F48533;

}

.popup-konw-more {
  position: fixed;
  margin-top: -100px;
  z-index: 1000;
  width: 15vw;
  height: 5vw;
  background-color: rgb(175, 121, 121);
  color: black;
  border-radius: 5px;
  border: none;

}

.know-erroe-text {
  color: #F94D1C;
  font-size: 12px;
  margin-top: 3px;
}

.react-tel-input {
  font-size: 16px;
  background: #2c2b2b;

  border-radius: 5px;
  width: 100%;
  outline: none;

  transition: box-shadow ease .25s, border-color ease .25s;
  color: #ffffff;

}

.react-tel-input .know-schedule-inputs {
  width: 100% !important;
  height: 48px !important;

  border: none !important;
  outline: none !important;
  color: #ffff !important;

  background-color: #2c2b2b !important;
  ;
  border: 2px solid gray !important;
  border-radius: 10px !important;



}

.react-tel-input .know-schedule-inputs:focus {
  background: none !important;
  border: none !important;
  outline: none !important;
  border: 2px solid #F48533 !important;
  color: #ffff !important;
}

.react-tel-input .know-schedule-inputs:placeholder {
  color: #ffff !important;
}

.react-tel-input .search {
  background-color: #2c2b2b !important;
}

.react-tel-input .country-list {
  background-color: #2c2b2b !important;
}

/* react-tel-input */
.software-service-service {
  padding: 50px 0px;

  .know-our-divs {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: linear-gradient(135deg, #2A3039 0%, rgba(42, 48, 57, 0.00) 100%);
    color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
  }
}

