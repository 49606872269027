.whyChoose_section {
    background-color: #fafafb;
    padding: 5em 0em;

    .head_section {
        text-align: center;

        .h2_title {
            font-size: 2rem;
            padding-bottom: 20px;
            max-width: 900px;
            margin: 0px auto;

            span {
                color: #36bbc2;
                font-family: heading;
                font-size: 2rem;
                letter-spacing: 1px;
            }
        }

        .h4_title {
            color: #36bbc2;
        }
    }

    .box_div {
        text-align: center;
        box-shadow: #00054514 0px 0px 16px;
        height: auto;
        width: 80%;
        margin: 1em auto;
        padding: 2em;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: #fff;

        img {
            width: 50px;
            margin-bottom: 1em;
        }
    }
}

@media screen and (min-width: 767px) and  (max-width: 991px) {
    .whyChoose_section .box_div {
        min-height: 360px;
        width: 100%;
    }
}
@media screen and (max-width: 575px) {
    .whyChoose_section .head_section .h2_title{
        font-size: 1em;
    }
}