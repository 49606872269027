.solution_section {
    padding: 4em 0em 4em;
    background-color: #eeeeee54;
    .head {
        margin-bottom: 2em;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h2{
            font-size: 2rem;
        }
    }

    .solution_content {
        padding: 1.5em;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        margin: 1em 0em;
        height: 267px;;
        background-color: #fff;
        img {
            width: 50px;
            margin-bottom:10px;
            height: 50px;
        }
        @media (min-width:300px) and (max-width:449px){
            height: 309px
        }
        @media (min-width:450px) and (max-width:550px){
            height: 242px
        } 
        @media (min-width:551px) and (max-width:991px){
            height: 221px
        }     
        // @media (min-width:992px) and (max-width:1023px){
        //     height: 360px
        // }     
        
        @media (min-width:992px) and (max-width:1439px){
            height: 360px;
        }

    }
}