.details-wrap{
    position: relative;
}
.listar-map-button {
    width: 100%;
    position: absolute;
    display: table;
    right: 0;
    top: 0;
    height: 200px;
    line-height: 164px;
    white-space: nowrap;
    font-size: 0;
}

.listar-map-button {
    right: auto;
    /***
      box-shadow: 0 0 65px rgba(0,0,0,.07) inset, 0 0 35px rgba(0,0,0,.05) inset;**/
}

.listar-map-button-text span {
    background-color: #fff;
    color: #252525;
    text-shadow: none;
}

.listar-page-header-content .listar-map-button-text span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    height: 44px;
    line-height: 1.6;
    padding: 10px 25px;
    box-shadow: 0 0 300px rgba(0, 0, 0, 0.65), 0 0 30px rgba(0, 0, 0, 0.06);
    border-radius: 50px;
    background-color: rgba(35, 40, 45, 0.9);
    color: #fff;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}

header .footer-wave {
    max-width: 102%;
    width: 100%;
    height: 187.8px;
    left: 0;
    z-index: 1;
    bottom: -67px;
    background: url(https://1.bp.blogspot.com/-NYl6L8pz8B4/XoIVXwfhlNI/AAAAAAAAU3k/nxJKiLT706Mb7jUFiM5vdCsOSNnFAh0yQCLcBGAsYHQ/s1600/hero-wave.png) repeat-x;
    animation: wave 10s cubic-bezier(0.44, 0.66, 0.67, 0.37) infinite;
}

@keyframes wave {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 1440px;
    }
}

.listar-feature-item-wrapper {
    margin-bottom: 120px;
    padding-left: 27px;
    padding-right: 27px;
}

.listar-feature-item {
    height: calc(100% - 20px);
}

.listar-feature-item.listar-feature-has-link~.listar-feature-fix-bottom-padding.listar-fix-feature-arrow-button-height {
    position: relative;
    display: block;
    width: 100%;
    height: 30px;
}

.listar-feature-item a {
    position: absolute;
    top: -12px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100% + 4px);
    border-radius: 1000px;
    z-index: 10;
}

.listar-feature-with-image .listar-feature-item a:before {
    content: "";
    position: absolute;
    top: -74px;
    left: 50%;
    margin-left: -74px;
    width: 148px;
    height: 148px;
    border-radius: 1000px;
    z-index: 10;
}

.listar-feature-item a:after {
    content: "";
    position: absolute;
    bottom: -17px;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border-radius: 1000px;
    z-index: 10;
    animation: ripple 0.7s linear infinite;
    box-shadow: 5px 5px 10px rgba(163, 177, 198, 0.6),
        -5px -5px 10px rgba(255, 255, 255, 0.5);
}


@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(163, 177, 198, 0.3), 0 0 0 1em rgba(163, 177, 198, 0.3), 0 0 0 3em rgba(163, 177, 198, 0.03), 0 0 0 5em rgba(163, 177, 198, 0.01);
    }

    100% {
        box-shadow: 0 0 0 1em rgba(163, 177, 198, 0.3), 0 0 0 3em rgba(163, 177, 198, 0.03), 0 0 0 5em rgba(163, 177, 198, 0.03), 0 0 0 8em rgba(163, 177, 198, 0.01);
    }
}



.listar-feature-item .listar-feature-item-inner {
    padding: 50px 30px;
    border-radius: 6px;
    z-index: 5;
    position: relative;
    height: 100%;
}

.listar-feature-item .listar-feature-item-inner {
    padding-top: 60px;
    padding-bottom: 60px;
}

.listar-feature-item .listar-feature-item-inner:before {
    border: 0;
    box-shadow: 120px 0px 150px rgba(80, 80, 80, 0.15),
        10px 0px 10px rgba(80, 80, 80, 0.02);
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0);
    background: -moz-linear-gradient(left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(left top,
            right top,
            color-stop(0%, rgba(255, 255, 255, 0)),
            color-stop(40%, rgba(255, 255, 255, 0)),
            color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
}

.listar-feature-item .listar-feature-item-inner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.listar-feature-right-border {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 50%;
    overflow: hidden;
}

.listar-feature-items {
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.listar-feature-right-border:before,
.listar-hovering-features .listar-feature-item a:hover~.listar-feature-item-inner .listar-feature-right-border:before {
    border: 11px solid #2f53bf;
}

.listar-feature-right-border:before,
.listar-hovering-features .listar-feature-item a:hover~.listar-feature-item-inner .listar-feature-right-border:before,
.listar-hovering-features-grey .listar-feature-item a:hover~.listar-feature-item-inner .listar-feature-right-border:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 11px solid orange;
    border-radius: 800px;
    left: -50%;
}

.listar-feature-item .listar-feature-block-content-wrapper {
    position: relative;
    display: block;
    text-decoration: none;
    margin: -30px;
    padding: 30px;
    height: calc(100% + 100px);
}

.listar-features-design-2 .listar-feature-item-wrapper {
    margin-bottom: 120px;
    padding-left: 27px;
    padding-right: 27px;
}

.listar-feature-item.listar-feature-has-link .listar-feature-item-inner:after {
    content: "";
    background-color: #fff;
    z-index: 6;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
}

.listar-feature-icon-wrapper {
    width: 148px;
    height: 148px;
    line-height: 148px;
    border-radius: 500px;
    position: relative;
    background-color: #fff;
    box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.06);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 50%;
    vertical-align: middle;
    margin-left: -74px;
    top: -66px;
    margin-top: -74px;
}

.listar-feature-icon-inner {
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 500px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.14);
    background: #fff;
}

.listar-feature-icon-inner div {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    display: inline-block;
    position: relative;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: left;
}

.listar-feature-icon-inner img {
    position: relative;
    margin: 0 auto;
    vertical-align: middle;
    display: inline-block;
    width: auto;
    left: 50%;
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
    transform: translate(-50%);
    height: 55px;
}

.listar-feature-item img,
.listar-feature-item i {
    position: relative;
    display: inline-block;
    border-bottom: 0;
    font-size: 40px;
    top: 0;
    padding: 0;
    color: #258bd5;
}

.listar-feature-with-image .listar-feature-content-wrapper {
    top: 0;
}

.listar-feature-content-wrapper {
    position: relative;
    margin-top: -15.5px;
}

.listar-feature-item .listar-feature-item-title {
    padding-top: 0;
    margin: 0 0 30px;
    text-align: center;
    line-height: 1.5;
}

.listar-feature-item .listar-feature-item-title.listar-feature-counter-added>span {
    margin-left: 25px;
    border-radius: 0 50px 50px 0;
}

.listar-feature-item .listar-feature-item-title>span {
    box-shadow: 15px 20px 30px rgba(80, 80, 80, 0.12),
        5px 0px 40px rgba(80, 80, 80, 0.1);
    padding: 10px 20px;
    display: inline-block;
    position: relative;
    background-color: #fff;
}

.listar-feature-item .listar-feature-item-title>span span {
    box-shadow: 15px 15px 30px rgba(80, 80, 80, 0.2),
        5px 0px 80px rgba(80, 80, 80, 0.15);
    padding: 10px 0;
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 30px;
    white-space: nowrap;
    position: absolute;
    top: -3px;
    left: -40px;
    border-radius: 50px;
    background-color: #fff;
}

.listar-feature-item .listar-feature-item-title span {
    display: inline-block;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    text-shadow: none;
    border-radius: 50px;
    color: #252525;
    font-size: 18px;
}

.listar-feature-item-excerpt {
    padding: 0 20px;
}

.listar-feature-item-excerpt {
    color: #252525;
    position: relative;
    top: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.pset {
    padding-top: 180px;
    padding-bottom: 80px;
    .row{
        justify-content: space-around;
    }
}

.listar-map-button-text span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    height: 44px;
    line-height: 1.6;
    padding: 10px 25px;
    box-shadow: 0 0 31px rgba(0, 0, 0, 0.65), 0 0 4px rgba(0, 0, 0, 0.06);
    border-radius: 50px;
    background-color: rgba(35, 40, 45, 0.9);
    color: #fff;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}

.listar-map-button-text span {
    background-color: #fff;
    color: #252525;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.listar-map-button-text span:after {
    border: 12px solid #fff;
}

.listar-map-button-text span:after {
    content: "";
    position: absolute;
    top: -14px;
    left: -14px;
    width: calc(100% + 28px);
    height: calc(100% + 28px);
    border-radius: 36px;
}

.listar-map-button:hover .listar-map-button-text span {
    padding: 10px 43px 10px 51px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.listar-feature-with-image .listar-feature-item a:before {
    content: "";
    position: absolute;
    top: -74px;
    left: 50%;
    margin-left: -74px;
    width: 148px;
    height: 148px;
    border-radius: 1000px;
    z-index: 10;
}

.listar-feature-item.listar-feature-has-link .listar-feature-item-inner:after {
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    box-shadow: 10px 10px 40px rgba(80, 80, 80, 0.15),
        15px 15px 30px rgba(80, 80, 80, 0.05), 0 0 120px rgba(80, 80, 80, 0.6);
    bottom: -30px;
    background-image: url("https://image.flaticon.com/icons/svg/2316/2316674.svg");
    background-size: 40%;
    color: #555;
    line-height: 52px;
}

// .col-lg-1,
// .col-lg-2,
// .col-lg-3,
// .col-lg-4,
// .col-lg-5,
// .col-lg-6,
// .col-lg-7,
// .col-lg-8,
// .col-lg-9,
// .col-lg-10,
// .col-lg-11,
// .col-lg-12,
// .col-md-1,
// .col-md-2,
// .col-md-3,
// .col-md-4,
// .col-md-5,
// .col-md-6,
// .col-md-7,
// .col-md-8,
// .col-md-9,
// .col-md-10,
// .col-md-11,
// .col-md-12,
// .col-sm-1,
// .col-sm-2,
// .col-sm-3,
// .col-sm-4,
// .col-sm-5,
// .col-sm-6,
// .col-sm-7,
// .col-sm-8,
// .col-sm-9,
// .col-sm-10,
// .col-sm-11,
// .col-sm-12,
// .col-xs-1,
// .col-xs-2,
// .col-xs-3,
// .col-xs-4,
// .col-xs-5,
// .col-xs-6,
// .col-xs-7,
// .col-xs-8,
// .col-xs-9,
// .col-xs-10,
// .col-xs-11,
// .col-xs-12 {
//     align-self: flex-start;
// }
// }
