.term_condition_section{
    
    .head{
        padding: 5em 0em;
        background-color: #fafafb;
        text-align: center;

        p{
            width: calc(100% - 40%);
            margin: auto;
        }
    }

    .term_condition_content{
        margin: 2em 0em;

        h4{
            border-left: 4px solid orange;
            padding-left: 10px;
            margin-bottom: .5em;
            margin-top: 1em;
            font-weight: 600;
            font-size: 18px;
        }
    }
}
@media screen and (max-width: 767px) {
    .term_condition_section .head p{
        padding: 0px 15px;
        width: 100%;
    }
}