.main_section {
    min-height: 600px;
    display: flex;
    align-items: center;
    // background: #0F1013;
    background-image: url('../../../media/new-landing/main-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .bg-box {
        position: absolute;
        right: 10%;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 14px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.00) 109.98%);
        min-width: 963px;
        min-height: 474px;
        @media screen and (max-width: 660px) {
            min-width: 426px;
        }
        @media screen and (max-width: 456px) {
            min-width: 365px;
        }
        @media screen and (max-width: 340px) {
            min-width: 269px;
        }
    
    }

    .bg-grains {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-image: url('../../../../assets/media/new-landing/grains.png');
    }


    .container {
        .row{
            align-items: center;

            @media screen and (max-width:768px) {
                flex-direction: column-reverse;
            }
        }
        z-index: 1;

        .content {
            position: relative;

            svg.side {
                position: absolute;
                top: 0%;
                left: 0%;
            }

            h1 {
                color: #fff;
                font-weight: 700;
                font-size: 60px;

                @media screen and (min-width:280px) and (max-width:991px) {
                    font-size: 35px;
                }

                span {
                    color: #F68633;
                }

                span.provide {
                    color: #fff;
                    margin-left: .5em;
                }

                span.it-solutions {
                    position: relative;

                    svg {
                        position: absolute;
                        bottom: 0%;
                        left: 0%;
                        width: 100%;
                    }

                    svg:nth-child(2) {
                        bottom: -7px;
                    }
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: #F9F9F9;
                margin-top: 4px;
                @media screen and (min-width:280px) and (max-width:991px) {
                    font-size: 14px;
                }
            }
        }
    }

}
