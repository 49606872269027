header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .img_wrapper {
        padding: 1em;

        img {
            max-width: 200px;
        }
    }

    .contact_wrapper {
        padding: 1em;

        p {
            font-size: 1.1em;
        }
    }
}

footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    background-color: #1d1d1d;
    width: 100%;

    .copyright {
        color: #fff;
    }

    .social_links {
        svg {
            margin: 0 1em;
            width: 20px;
            height: 20px;
            color: #fff;
        }
    }
}

.leaf_prediction_page {
    padding: 4em 0;
    min-height: 100vh;

    .drop_box {
        position: relative;
    }

    .choose_image_btn {
        width: fit-content;
        margin: auto;
        position: absolute;
        border: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);


        input {
            // visibility: hidden;

            &.video_input {
                &::before {
                    content: '+ choose video';
                    display: grid;
                    place-items: center;
                    border-radius: 5px;
                    color: #fff;
                    font-weight: bold;
                    cursor: pointer;
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    background-color: #8BC34A;
                    width: 100%;
                    height: 100%;
                }
            }
            &::before {
                content: '+ choose leaf image';
                display: grid;
                place-items: center;
                border-radius: 5px;
                color: #fff;
                font-weight: bold;
                cursor: pointer;
                position: absolute;
                top: 0%;
                left: 0%;
                background-color: #8BC34A;
                width: 100%;
                height: 100%;
            }
        }
    }

    .choose_file_wrapper {
        border: 4px dashed #b4b4b4;
        padding: 1em;
        width: 100%;
        height: 400px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
        z-index: -1;

        video {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .form-control:focus,
    .form-select:focus {
        border-color: #8BC34A;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(76 175 80 / 29%);
    }
}