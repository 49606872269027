.store_wrap {
    padding: 70px 0px 50px;
    // background-image: url("../../../images/background/service_background.jpg");
    // background-repeat: no-repeat;
    background-color: #fafafb;
    .store_details {
        .store_img {
            width: 80%;
            margin: auto;
        }
        .store_text {
            .h4_title {
                color: #36bbc2;
            }
            .h2_title{
                color: #0f2851;
            }

            button {
                margin-top: 20px;
                background: transparent;
                padding: 10px 45px;
                border: 1px solid #36bbc2;
                position: relative;
                overflow: hidden;
                transition: all 0.2s;
                z-index: 0;
                border-radius: 25px;
                color: #000;

                &:hover {
                    color: #fff;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -10px;
                    left: -10px;
                    width: 10px;
                    height: 10px;
                    background-image: linear-gradient(45deg, #79dee3, #36bbc2, #79dee3);
                    border-radius: 50%;
                    border: 1px solid #79dee3;
                    transition: all 0.2s;
                    z-index: -1;
                }

                &:hover::before {
                    transform: scale(60);
                }
            }
        }
        .store_div {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
}


@media screen and (max-width: 575px){
    .store_wrap .store_details .store_img{
        margin-bottom: 2em;
    }
}
@media screen and (max-width: 991px){
    .store_wrap .store_details .store_img{
        width: 100%;
    }
}