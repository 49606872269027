.technology_wrap {
    padding: 50px 0px;

    .technology_details {

        .technology_img{
            width: 80%;
            margin: auto;
        }

        .h4_title {
            color: #36bbc2;
        }

        .h2_title {
            padding: 5px 0px 15px;
            color: #0f2851;
        }

        button {
            margin-top: 20px;
            background: transparent;
            padding: 10px 45px;
            border: 1px solid #36bbc2;
            position: relative;
            overflow: hidden;
            transition: all 0.2s;
            z-index: 0;
            border-radius: 25px;
            color: #000;

            &:hover {
                color: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -10px;
                width: 10px;
                height: 10px;
                background-image: linear-gradient(45deg, #79dee3, #36bbc2, #79dee3);
                border-radius: 50%;
                border: 1px solid #36bbc2;
                transition: all 0.2s;
                z-index: -1;
            }

            &:hover::before {
                transform: scale(60);
            }
        }

        .technology_div {
            height: 100%;
            display: flex;
            align-items: center;
        }


    }
}

// ========================= responsive ==============================
@media screen and (max-width: 480px) {
    .technology_img {
        padding-top: 15px;
    }

    .technology_wrap {
        padding: 10px 0px;
    }
}

@media screen and (max-width: 575px) {
    .technology_details .row {
        flex-direction: column-reverse;
    }
    .technology_wrap .technology_details .technology_img{
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 991px){
    .technology_wrap .technology_details .technology_img{
        width: 100%;
    }
}