.meetUpGallery {
    padding: 4em 0em;
    position: relative;

    .circles:nth-child(1) {
        position: absolute;
        top: 10%;
        left: 14%;
        width: 10px;
        height: 10px;
        background-color: #fe646c;
        border-radius: 50%;
    }

    .circles:nth-child(2) {
        position: absolute;
        top: 15%;
        left: 10%;
        width: 20px;
        height: 20px;
        background-color: #87d9f7;
        border-radius: 50%;
    }

    .circles:nth-child(3) {
        position: absolute;
        bottom: 10%;
        right: 20%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #f58634;
    }
    .row {
        justify-content: center;

        .head {
            // margin-bottom: 2em;
            
            h2 {
                text-align: center;
                font-family: monaSansRegular;
                font-weight: 600;
            }
        }

        .imageWrapper {
            margin: 2em 0em;
            img{
                height: 400px;
            }
        }
    }
}

@media screen and (max-width:991px) {
    .meetUpGallery .row .imageWrapper img{
        height: 255px;
    }
}

@media screen and (max-width:767px){
    .meetUpGallery .row .imageWrapper{
        width: 50%;
        margin: auto;
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

@media screen and (max-width:575px){
    .meetUpGallery .row .imageWrapper{
        width: 100%;
    }
}