.solution_section {
    .row{
        align-items: center;
    }
    padding: 50px 0px;
    .head_text {
        text-align: center;
        .h2_title{
            padding-bottom: 20px;
        }
    }

    .solution_container {
        display: flex;
        align-items: center;
        height: 100%;

        .content_box {
            padding: 2em;
            border-radius: 5px;
            margin-bottom: 1em;
            // box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
            box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
        }
    }
}
@media screen and (max-width: 575px) {
    .solution_section{
        padding: 20px 0px;
    }
}


