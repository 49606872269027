.similarGoal {
    background-image: linear-gradient(-90deg, #edebea, #f9f9f7);
    position: relative;

    .circles:nth-child(1) {
        position: absolute;
        top: 20%;
        left: 35%;
        width: 10px;
        height: 10px;
        background-color: #fe646c;
        border-radius: 50%;
    }


    .circles:nth-child(2) {
        position: absolute;
        bottom: 10%;
        right: 15%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #f58634;
    }

    .wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        .contentDiv {
            h2 {
                font-family: monaSansRegular;
            font-weight: 600;
            color: #393939;
            }
            p{
                font-family: monaSansRegular;
                font-weight: 200;
                font-size: 15px;
                padding-top: 15px;
            }
        }
    }
}


@media screen and (max-width:767px) {
    .similarGoal {
        padding: 4em 0em;
        .row {
            flex-direction: column-reverse;
            .imageWrapper{
                width: 50%;
                margin: auto;
            }
        }
    }

    .similarGoal .wrapper .contentDiv{
        text-align: center;
    }

}