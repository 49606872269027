.section_three {
    background-image: linear-gradient(90deg, #EDEBEA 0%, #F9F9F7 100%);
    padding: 4em 0;
    position: relative;

    &::before {
        background-image: url('../../../../assets/media/new-landing/white-grains.png');
        content: '';
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
    }

    .container {
        z-index: 1;
        position: relative;
    }

    .row {
        padding: 2em 0;
    }

    .content {
        display: flex;
        align-items: center;

        h2 {
            color: #14181E;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1px;

            @media screen and (min-width:280px) and (max-width:991px) {
                font-size: 35px;
            }
        }

        .btn_group {
            display: flex;
            margin-top: 2em;
            flex-wrap: wrap;

            button:nth-child(1) {
                color: #fff;
                font-weight: 600;
                font-size: 1.1em;
                border-radius: 46px;
                background: #1F2122;
                padding: .2em 1em;
                border: none;
                display: flex;
                align-items: center;
                margin-right: 1em;

                @media screen and (min-width:280px) and (max-width:991px) {
                    height: 50px;
                }

                svg {
                    margin-right: 1em;

                    path {
                        fill: #fff;
                    }
                }
            }

            a {
                font-weight: 600;
                border-radius: 46px;
                background: rgba(0, 0, 0, 0.08);
                padding: .2em 1em .2em 0em;
                border: none;
                display: flex;
                align-items: center;
                margin-right: 1em;
                font-size: 1.1em;

                &:hover{
                    color: #14181E;
                }

                &>div {
                    background-color: #1F2122;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1em;
                }

                svg {
                    path {
                        fill: #fff;
                    }
                }
            }

            button {
                @media screen and (min-width:280px) and (max-width:991px) {
                    margin: .5em 0;
                }
            }
        }
    }

    .svg_wrapper_ani {
        position: relative;
        height: 100%;
        display: flex;

        @media screen and (max-width:1199px) {
            margin-top: 2em;
        }

        .text {
            color: #000;
            font-size: 36.817px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.767px;
            transform: rotate(35deg);
            height: fit-content;

            @media screen and (max-width:1512px) {
                font-size: 26.817px;
            }

            @media screen and (max-width:1318px) {
                font-size: 16.817px;
            }

        }

        .text._1 {
            position: absolute;
            bottom: 90px;
            left: -30px;
            opacity: 0;
            animation: show_text 1s linear 1 forwards;
            animation-delay: 1s;

            @media screen and (max-width:1178px) {
                bottom: 130px;
                left: 178px;
            }

            @media screen and (max-width:991px) {
                left: 60px;
            }

            @media screen and (min-width:280px) and (max-width:991px) {
                left: -10px;
                bottom: 154px;
            }
        }

        .text._2 {
            position: absolute;
            top: 90px;
            left: 54px;
            animation: show_text 1s linear 1 forwards;
            animation-delay: 3s;
            opacity: 0;

            @media screen and (max-width:1178px) {
                left: 294px;
            }

            @media screen and (max-width:991px) {
                left: 181px;
            }

            @media screen and (min-width:280px) and (max-width:991px) {
                left: 27px;
                top: 159px;
            }
        }

        .text._3 {
            position: absolute;
            top: 90px;
            left: 190px;
            animation: show_text 1s linear 1 forwards;
            animation-delay: 4s;
            opacity: 0;

            @media screen and (max-width:1178px) {
                left: 438px;
            }

            @media screen and (max-width:991px) {
                left: 329px;
            }

            @media screen and (min-width:280px) and (max-width:991px) {
                left: 86px;
            }
        }

        .text._4 {
            position: absolute;
            bottom: 90px;
            left: 110px;
            animation: show_text 1s linear 1 forwards;
            animation-delay: 6s;
            opacity: 0;

            @media screen and (max-width:1178px) {
                bottom: 115px;
                left: 327px;
            }

            @media screen and (max-width:991px) {
                left: 215px;
            }

            @media screen and (min-width:280px) and (max-width:991px) {
                left: 55px;
            }
        }

        .text._5 {
            position: absolute;
            bottom: 90px;
            left: 200px;
            animation: show_text 1s linear 1 forwards;
            animation-delay: 7s;
            opacity: 0;

            @media screen and (max-width:1178px) {
                bottom: 118px;
                left: 445px;
            }

            @media screen and (max-width:991px) {
                left: 330px
            }

            @media screen and (min-width:280px) and (max-width:991px) {
                left: 104px;
            }
        }

        .text._6 {
            position: absolute;
            top: 90px;
            left: 350px;
            animation: show_text 1s linear 1 forwards;
            animation-delay: 10s;
            opacity: 0;

            @media screen and (max-width:1178px) {
                left: 580px;
            }

            @media screen and (max-width:991px) {
                left: 461px;
            }

            @media screen and (min-width:280px) and (max-width:991px) {
                left: 162px;
            }
        }

        .text._7 {
            position: absolute;
            top: 120px;
            right: -100px;
            animation: show_text 1s linear 1 forwards;
            animation-delay: 12s;
            opacity: 0;

            @media screen and (max-width:1318px) {
                top: 169px;
                right: -38px;
            }

            @media screen and (max-width:1178px) {
                right: 144px;
            }

            @media screen and (max-width:991px) {
                right: 36px;
            }

            @media screen and (min-width:280px) and (max-width:991px) {
                right: -4px;
            }
        }

        @keyframes show_text {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .dot_1 {
            position: absolute;
            bottom: 47px;
            left: -4px;
            width: 10px;
            height: 10px;
            background-color: #000;
            border-radius: 50%;
        }

        .dots {
            position: absolute;
            bottom: 51px;
            left: -3px;
            width: 101.5%;

            @media screen and (max-width:1178px) {
                bottom: 81px;
                left: -6px;
            }
        }

        svg {
            width: 100%;
            // height: 100%;
            stroke-dashoffset: 3000;
            stroke-dasharray: 3000;
            animation: process 20s ease-in-out infinite;

            @keyframes process {
                0% {
                    stroke-dashoffset: 3000;
                    stroke-dasharray: 3000;
                }

                80% {
                    stroke-dashoffset: 3000;
                    stroke-dasharray: 6000;
                }

                100% {
                    stroke-dashoffset: 3000;
                    stroke-dasharray: 6000;
                }
            }
        }

        svg:nth-child(1) {
            height: auto;
        }
    }
}