.LaunchPlatform-wrap {
    padding: 5em 0em;
    // padding: 50px 0px;
    // background-color: #7079e60f;
    background-color: #fafafb;
    // background: linear-gradient(198deg, rgba(255, 255, 255, 0.618), rgba(0, 5, 69, 0.6862745098)), url();    
}

.LaunchPlatform-heading {
    padding-bottom: 40px;
    text-align: center;

    .h4_title {
        color: #36bbc2;
    }
}

.flip-card_EMPTY {
    background-color: #eeeeee;
    height: 330px;
    width: 300px;
    border-radius: 10px;
    margin: 1em auto;
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 330px;
    perspective: 1000px;
    // margin-bottom: 40px;
    margin: 0px auto 40px;
}

.flip-card-inner {
    border-radius: 20px;
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: #00054514 0px 0px 16px;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    // background-color: rgb(255, 255, 255);
    // padding: 20px;
    border-radius: 20px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;

    .tile_div {
        perspective: preserve-3d;
        transform: translateZ(50px);

        .h4_title {
            font-size: 2em;
            color: #fff;
        }
    }
}

.flip-card-back {
    // background-color: #ffffff;
    // color: white;
    // padding: 20px;
    // height: 330px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    transform: rotateY(180deg);
    border-radius: 20px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;

    .tile_div {
        perspective: preserve-3d;
        // transform: translateZ(50px);
        transform: translateZ(50px) scale(.90);

        .h4_title {
            font-size: 2em;
            color: #fff;
        }

        p {
            color: #fff;
        }
    }
}

.flip-card-front {
    .h4_title {
        margin-top: 10px;
    }
}

@media screen and (max-width: 1024px) {
    .flip-card .flip-card-inner {
        transform: rotateY(180deg);
    }
}

@media screen and (max-width: 767px) {
    .flip-card {
        width: 230px;
        height: 285px;
    }

}

@media screen and (max-width: 575px) {
    .flip-card {
        width: 300px;
        height: 330px;
    }

}

@media screen and (max-width: 320px) {
    .flip-card {
        width: 250px;
        height: 305px;
    }
}