.AboutAnalysis-wrap {
    padding: 50px 0px;

    .row {
        align-items: center;
    }

    .AboutAnalysis-text {
        .h3_title {
            color: #36ccb2;
        }
        p{
            padding-top: 20px;
        }

        button {
            background: transparent;
            padding: 10px 45px;
            border: 1px solid #36ccb2;
            position: relative;
            overflow: hidden;
            transition: all 0.2s;
            z-index: 0;
            border-radius: 25px;
            color: #000;
            margin-top: 30px;

            &:hover {
                color: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -10px;
                width: 10px;
                height: 10px;
                background-image: linear-gradient(45deg, #36ccb2, #36ccb2, #36ccb2);
                border-radius: 50%;
                border: 1px solid #36ccb2;
                transition: all 0.2s;
                z-index: -1;
            }

            &:hover::before {
                transform: scale(100);
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .AboutAnalysis-text {
        margin-top: 20px;
    }

    .AboutAnalysis-wrap {
        padding: 20px 0px;
    }

    .AboutAnalysis-wrap .AboutAnalysis-text button {
        padding: 5px 15px;
        margin-top: 15px;
        font-size: 14px;
    }

    .AboutAnalysis-img{
        margin-bottom: 20px;
    }
}