.notFound_section {
    margin: 5em 0em;
    .notFound_div {
        text-align: center;

        img {
            width: 50%;
        }

        h6 {
            margin-top: 2em;
        }

        h2 {
            margin: 0.5em 0em;
        }
        .go_back_btn {
            margin-top: 3em;
            a {
                background: #36bbca;
                padding: 10px 45px;
                border: 1px solid #36bbca;
                position: relative;
                overflow: hidden;
                transition: all 0.5s;
                z-index: 0;
                border-radius: 25px;
                color: #000;
                box-shadow: 0px 0px 20px #36bbca;

                &:hover{
                    box-shadow: 0px 0px 5px #36bbca;
                }
            }
        }
    }
}
